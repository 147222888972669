import { NotiPropsData } from "contents/func/type/typeinter"
import { createContext, useEffect} from "react"
import { NotiRow, NotiRowSkel } from "./row";
import styles from "./notification.module.css";
import { AxiosIns } from "contents/func/network/axios";
import { useQuery } from "react-query";
import { HasAlertRefreshedAtom } from "contents/state/state";
import { useAtom } from "jotai";

export const notiContext = createContext<any>(undefined);

const NotiList = () =>{
    
    return(
        <NotiMap className={styles.listPage}/>
    );
}
//<NotiMap list={list} className={styles.listPage}/>
export const NotiMap = ({className} : {className : string}) =>{
    const [,setHasAlertRefreshed] = useAtom(HasAlertRefreshedAtom);
    const list = useQuery(['Get-Noti-List-Key'] ,
    () => AxiosIns.get('/api/restrict/user/getAllUnReadAlarms').then(res=>res.data)
    ,{
        enabled : false
    })

    useEffect(()=>{
        list.refetch();
        setHasAlertRefreshed(false);
        return ()=>list.remove();
    },[setHasAlertRefreshed]);

    return (
        <div style={{height : '100%'}}>
            {(list.isLoading)?
            (
                new Array(5).fill(1).map((_arr,i)=>(
                    <NotiRowSkel key = {i}/>
                ))
            ):(
                <div className = {className + ' scrolling'}>
                    {list?.data?.map((arr : NotiPropsData,i : Number)=>(
                        <notiContext.Provider value={{arr,i}} key={i.toString()}>
                            <NotiRow/>
                        </notiContext.Provider>
                    ))}
                </div>
            )}
        </div>
    );
}

export default NotiList;