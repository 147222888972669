import { createContext, useState } from "react";
import { Section } from "contents/func/section/section";
import { useQueries} from "react-query";
import { useSearchParams } from "react-router-dom";
import recomStyle from "./css/recom.module.css";
import cn from "classnames/bind";
import { AxiosIns } from "contents/func/network/axios";
import { CollectedMap, ListHeader } from "../list";
import { FeedSetting } from "contents/setting";

const recomContext = createContext<any>(null);

const RecomPage = () => {
    const [page,setPage] = useState<number>(0);
    const pageMax = 5;
    const [searchParam,setSearchParams] = useSearchParams();
    const category = searchParam.get('category')
    const fetchList = ['feed','users'];
    const list = useQueries(
        fetchList.map((tagList : string,i : number)=>({
            queryKey : ['GetMainContentListKey','Main-Tag-List-Key',tagList],
            queryFn : () => (category)&&fetch(tagList)
        }))
    )
    const cx = cn.bind(recomStyle);
    const fetch = async(string : string) => await AxiosIns.get(`/api/getPostByList/0?pageSize=${pageMax}&categoryId=${category}`).then(res=>res.data);
    return (
        <div className={cx("feedPage",'flex-column')}>
            <ListHeader/>
            <Section menus={['추천 피드','설정']} callback = {(_e : Event,i : number)=>setPage(i)}/>
            <recomContext.Provider value={{list}}>
                {
                    {
                        0: <CollectedMap className={cx("recomColMap","scrolling")} row={1} list={{isSuccess : list[0].isSuccess,data:{pages:[list[0]]}}} />,
                        1 : <FeedSetting/>
                    }[page as number]
                }
            </recomContext.Provider>
        </div>
    );
}

export default RecomPage;