import { useEffect, useState, useRef} from 'react';
import { EventSourcePolyfill , NativeEventSource } from "event-source-polyfill";
import logStyle from "./logViewer.module.css";
import cn from "classnames/bind";
import { CustomBtn, CustomToggle } from 'contents/func/anim/custom';
import { useAtomValue } from 'jotai';
import { CurrentUserAtom, pageSettingAtom } from 'contents/state/state';
import { TokenCheck } from 'contents/token/check';
import { useVirtualizer} from '@tanstack/react-virtual'
import { LeftBtn, RightBtn } from 'icons';
import { throttle } from 'lodash';

const cx = cn.bind(logStyle);

export const LogViewer = () => {
    const [logs, setLogs] = useState([]);
    const setUser = useAtomValue(CurrentUserAtom);
    const pageSetting = useAtomValue(pageSettingAtom);
    const token = localStorage.getItem('userToken');
    const logContainerRef = useRef(null);
    const eventSource = useRef();
    const [isAutoScroll, setIsAutoScroll] = useState(true);
    const EventSource = EventSourcePolyfill||NativeEventSource;
    const estimateSize = 85;
    const virtualizer = useVirtualizer({
        count : logs.length,
        getScrollElement : () => logContainerRef.current,
        estimateSize : () => estimateSize,
    })
    const items = virtualizer.getVirtualItems();

    const SSEFunc = (cb) => {
        eventSource.current = new EventSource('/api/restrict/admin/log/stream', {
            headers: {
                'Authorization': `${token}`
            },
        });

        eventSource.current.onmessage = async function (event) {
            virtualizer?.scrollToIndex(logs.length - 1);
            /*const jwt = event.target?.headers?.Authorization;
            if (jwt) await TokenCheck(jwt)
            .then((p)=>setUser(p))
            .catch((err)=>{
                console.log(err);
                localStorage.removeItem('userToken');
                window.location.replace(pageSetting.login);
            });*/
            cb(event);
        };

        eventSource.current.onerror = async function (event){
            if (event.status === 401){
                const jwt = event.target?.headers?.Authorization;
                await TokenCheck(jwt)
                .then((p)=>setUser(p))
                .catch((err)=>{
                    console.log(err);
                    localStorage.removeItem('userToken');
                    window.location.replace(pageSetting.login);
                });
            }
        }
    }

    const SSECallback = (event) =>{
        setLogs((prev) => [...prev, event.data]);
    }

    useEffect(() => {
        SSEFunc(SSECallback);
        return () => {
            eventSource.current?.close();
        };
    }, [eventSource,token,virtualizer]);

    const handleScroll = throttle(() => {
        if (logContainerRef.current) {
            const isAtBottom = logContainerRef.current.scrollTop + logContainerRef.current.clientHeight >= logContainerRef.current.scrollHeight - estimateSize;
            setIsAutoScroll(isAtBottom);
        }
    },500);

return (
<div>
    <h1> 서버 로그 </h1>
    <h4>관리자만 열람 가능합니다</h4>
    <div className={cx("flex-row",'logController')}>
        <div className={cx("flex-row")}>
            <CustomToggle on={isAutoScroll} onChange={() => setIsAutoScroll((prev) => !prev)}/>
            자동 스크롤
        </div>
        <div className={cx("flex-row")}>
            <CustomBtn className={cx("ScrollButton","scrollUp","flex-row")}  onClick={()=>virtualizer?.scrollToIndex(logs.length - 1)}>
                <RightBtn style={{transform : "rotate(90deg)"}} width = "16" height = "16"/>
            </CustomBtn>
            <CustomBtn className={cx("ScrollButton","scrollDown","flex-row")} onClick={()=>virtualizer?.scrollToIndex(0)}>
                <LeftBtn style={{transform : "rotate(90deg)"}} width = "16" height = "16"/>
            </CustomBtn>
        </div>
    </div>
    
    <div className={cx("log-container")} ref={logContainerRef} onScroll={handleScroll}>
        <div style={{
            height: virtualizer.getTotalSize(),
            width: '100%',
            position: 'relative',
        }}>
            <div
                style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${items[0]?.start ?? 0}px)`,
                }}
            >
            {items.map((log) => (
                <p key = {log.key}
                data-index = {log.index}
                ref = {virtualizer.measureElement}
                className={
                    cx(`log-entry`,
                    {error : logs[log.index].toLowerCase()?.includes('error')},
                    {warn : logs[log.index].toLowerCase()?.includes('warn')},
                    {info : logs[log.index].toLowerCase()?.includes('info')},
                    {debug : logs[log.index].toLowerCase()?.includes('debug')},
                    )}>
                        {logs[log.index]}
                </p>
            ))}
            </div>
        </div>
    </div>
</div>
);
};
/*
${log.toLowerCase().includes('error')?
'error' : log.toLowerCase().includes('warn')?
'warn' : log.toLowerCase().includes('info')?
'info' : log.toLowerCase().includes('debug')?
'debug' : ''}`
*/