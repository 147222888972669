import Hls from "hls.js";
import { LoadingSpiner, VideoBtn } from "icons";
import { forwardRef, Fragment, useEffect, useRef, useState} from "react";
import videoStyles from "./css/video.module.css";
import cn from "classnames/bind"
import { LoadingBar } from "contents/func/anim/loading";

const cx = cn.bind(videoStyles)
export const HlsReading = forwardRef(({src , autoPlay = false , cb , intersection}
    : {src : string , autoPlay : boolean , cb? : Function , intersection? : Boolean}
    ,ref : any) =>{
    const [loading,setLoading] = useState<Boolean>(true);
    const videoRef = useRef();
    const selectedRef = ref||videoRef;
    
    useEffect(()=>{
        const hls = new Hls();
        if (Hls.isSupported()&&selectedRef?.current){
            setTimeout(()=>hls.loadSource(src),1500);
            hls.attachMedia(selectedRef?.current);
        } else if(selectedRef?.current?.canPlayType('application/vnd.apple.mpegurl')){
            selectedRef.current.src = src;
        }
        hls.on(Hls.Events.ERROR,(_e,{details})=>{
            if (details === Hls.ErrorDetails.BUFFER_STALLED_ERROR){
                setLoading(true);
            }
        })
        hls.on(Hls.Events.FRAG_BUFFERED,()=>{
            setLoading(false);
        });
        return ()=>{
            hls.off(Hls.Events.ERROR,(_e,{details})=>{
                if (details === Hls.ErrorDetails.BUFFER_STALLED_ERROR){
                    setLoading(true);
                }
            })
            hls.off(Hls.Events.FRAG_BUFFERED,()=>{
                setLoading(false);
            });
        }
    },[]);

    return(
        <Fragment>
            <video width="100%" height="100%"
            ref={selectedRef} controls = {false} muted={true}
            autoPlay = {autoPlay} loop={true} className={cx("video","flex-row")}
            onClick={(e)=>{if(cb) cb(e)}}
            >
                <LoadingBar
                style={{
                    width : 64 ,
                    height : 64 ,
                    position : 'static'
                }}/>
            </video>
        </Fragment>
    );
});

export const HlsView = forwardRef(({src} : {src : string },ref : any) =>{
    return(
        <div>
            <HlsReading src={src} autoPlay={true} ref={ref}/>
        </div>
    );
})