import { createContext, Fragment, useEffect, useState } from "react";
import { useInfiniteQuery} from "react-query"
import { Row, SkelRow} from "./row";
import { useLocation, useNavigate, useSearchParams} from "react-router-dom";
import { ColRow, SkelColRow } from "./colRow";
import { AxiosIns } from "contents/func/network/axios";
import { PropsData } from "contents/func/type/typeinter";
import { ApiSettingAtom, CollectedBooleanAtom, WindowStateAtom, pageSettingAtom} from "contents/state/state";
import { LoadingBar } from "contents/func/anim/loading";
import ColStyles from "./css/colRow.module.css";
import DynamicMeta from "dynamicMeta";
import { useAtom, useAtomValue} from "jotai";
import { CustomImg} from "contents/func/anim/custom";
import rowStyle from "./css/row.module.css";
import cn from "classnames/bind";
import { ContentView } from "../view/view";
import TempImg from 'icons/user/logo.png';
import useIntersection from "contents/func/hooks/intersect";
import { TagListHeader } from "contents/func/tag/tagpage";
import { useSetAlert } from "contents/func/toast/toast";


const cx = cn.bind(rowStyle);
const cc = cn.bind(ColStyles);

export const listContext = createContext<any>(undefined);

export const IntersectionOption = {
    threshold : 0,
};
const ListMap = () =>{
    const pageMax = 10;
    const [searchParam,] = useSearchParams();
    const Alert = useSetAlert();
    const category = searchParam.get('category');
    const page = searchParam.get('page');
    const api = useAtomValue(ApiSettingAtom);
    const list = useInfiniteQuery({
        queryKey : ['Get-Lists-Key-Cate',category],
        queryFn : async({pageParam = 1})=>await AxiosIns.get(`${api.list}/${pageParam - 1}?pageSize=${pageMax}${(category)&&`&categoryId=${category}`}`),
        getNextPageParam: (lastPage, pages) => {
            if (lastPage?.data?.length >= pageMax) return pages.length + 1;
            else return undefined;
        },
        onError : (err)=>{
            console.log(err);
            Alert('서버에 오류가 생겼어요😢')
        },
        enabled : !page
    });
    
    const colview = searchParam.get('collected');
    const windowState = useAtomValue(WindowStateAtom);
    const navigate = useNavigate();
    const pageSetting = useAtomValue(pageSettingAtom);

    //프론트 디자인용 코드
    //const [list,] = useAtom(ContentStateAtom);

    useEffect(()=>{
        if (!page){
            if (!category){
                navigate(pageSetting.main);
            }
            if(windowState.mobile){
                window.scrollTo({top : Number(localStorage.getItem('scroll')) , behavior : 'auto'});
                localStorage.removeItem('scroll');
            }
        }
        else{
            window.scrollTo({top : 0});
        }
    },[page,category,pageSetting.main,navigate,windowState.mobile]);

    return (
        <div className={cx('list-page')}>
            {(page&&windowState.mobile)?
            (<ContentView/>)
            :
            (
                (colview)?
                <CollectedMap row={3} className={cc('collectedRow',"flex-row")} list={list}/>
                :<NormalMap className={cx("list-row-page")} list={list}/>
            )}
        </div>
    );
}

export const NormalMap = ({className , list }
    : {className? : string , list : any}) =>{
    const {ref,inView} = useIntersection(IntersectionOption);

    useEffect(()=>{
        if (inView&&list.hasNextPage){list.fetchNextPage();}
        return () => {};
    },[inView]);

    /* <div style={{width : '96px' , margin : 'auto'}}>
        <LoadingBar style={{position : 'static',transform : 'none'}}/>
    </div> */

    return (
        <Fragment>
            {(list.isLoading)?
            (
                <div className = {cx(className)}>
                    {new Array(5).fill(1).map((_arr,i)=>(
                        <SkelRow key={i}/>
                    ))}
                </div>
            ):(
                (list.isSuccess&&list.data?.pages?.length > 0)?
                (<div className = {cx(className)}>
                    {list.data?.pages?.map((p : any)=>
                        (p?.data?.length > 0)&&p?.data?.map((arr : PropsData,i : Number)=>(
                        <listContext.Provider value={{arr}} key={arr.id.toString()}>
                            <Row/>
                        </listContext.Provider>
                        )))
                    }
                    {(list.hasNextPage)&&<div ref={ref} style={{width : '100%' , height : '96px'}}>
                        {(inView)&&<LoadingBar style={{position : 'static',transform : 'none' , height : '100%'}}/>}
                    </div>}
                </div>)
                :
                <div>게시물이 없습니다..</div>
            )}
        </Fragment>
    );
}
export const CollectedMap = ({className , list , title  , row = 1}
     : {className? : string , list : any , title? : string , row : number}) => {
    const {ref,inView} = useIntersection(IntersectionOption);

    useEffect(()=>{
        if (inView&&list.hasNextPage){list.fetchNextPage()}
        return ()=> {};
    },[inView]);
    
    return (
        <Fragment>
            {(title)&&<p className={cx('')}>{title}</p>}
            {
            (list.isSuccess&&list.data?.pages?.length > 0)?
            (<div className = {cx(className)}>
                {list.data?.pages?.map((p : any)=>
                    (p?.data?.length > 0)&&p?.data?.map((arr : PropsData,i : Number)=>(
                    <listContext.Provider value={{arr}} key={arr.id.toString()}>
                        <ColRow row = {row}/>
                    </listContext.Provider>
                    )))
                }
                <div ref={ref} style={{width : '100%' , height : '96px'}}>
                    {(inView&&list.hasNextPage)&&<LoadingBar style={{position : 'static',transform : 'none' , height : '100%'}}/>}
                </div>
            </div>)
            :(
                <div className = {cx(className)}>
                    {new Array(15).fill(1).map((_,i)=>(
                        <SkelColRow key = {i} row = {row}/>
                    ))}
                </div>
            )}
        </Fragment>
    );
}


export const ListHeader = ({state , onClick ,
    className =
    {container : cx('list-row-header')
    ,title : cx('list-row-header-title')
    ,desc : cx('list-row-header-desc')}
    }
    : {state? : {title : string|null , description : string|null} , onClick? : Function
    ,className? : {container? : string , title? : string , desc? : string},children? : any}) =>{
    const Location = useLocation();
    const [searchParam,setSearchParam] = useSearchParams();
    const page = searchParam.get('page');
    const category = searchParam.get('category');
    const tag = searchParam.get('tag');
    const [header , setHeader] = useState<any>({categoryName : state?.title||'' , categoryDescription : state?.description||''});
    const ParamHandler = (type : string,value : any) => setSearchParam((p)=>{
        if (p.get(type)){p.delete(type)}else {p.set(type,value);}
        return p;
    });
    const [collected,setCollected] = useAtom(CollectedBooleanAtom);

    useEffect(()=>{
        if (!state) AxiosIns.get(`/api/categories/getAllCategoriesByParentId/${category}`)
        .then((res)=>setHeader(res.data[0]))
        .catch(()=>console.log('카테고리 불러오기 오류'));
        if (tag) setHeader({categoryName :  "#" + tag , categoryDescription : `${tag}를 추가한 게시물`});

        return()=>{
            setHeader({categoryName : state?.title||'' , categoryDescription : state?.description||''});
        }
    },[category]);

    return(
        <div className={cx(className.container,'flex-column')} onClick = {()=>{if (onClick) onClick();}}>
            {(!page)&&<DynamicMeta title={header.categoryName||''} description={header.categoryDescription||''} size={{width: "540px",height: "540px"}} url={Location.pathname} />}
            <div className={cx('prof-container-big')}>
                <CustomImg src={header.profile} alt="" altLink={TempImg}/>
            </div>
            <div className={cx('flex-column')} style={{maxWidth : '100%',alignItems:'start'}}>
                <h1 className={cx(className.title)}>{header.categoryName}</h1>
                <p className={cx(className.desc)}>{header.categoryDescription?`"${header.categoryDescription}"`:''}</p>
            </div>
            <div className={cx('list-header-bottom-menu','flex-row','only-pc-flex')} style={{width : '100%'}}>
                <TagListHeader/>

            </div>
        </div>
    );
}

export default ListMap;
