import { CustomBtn, CustomImg, CustomLabel} from "contents/func/anim/custom";
import infoStyle from "../css/info.module.css";
import cn from "classnames/bind";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSetAtom } from "jotai";
import { BigMenuObjectAtom, MenuTargetAtom } from "contents/state/state";
import { AxiosIns } from "contents/func/network/axios";
import TempImg from 'icons/user/logo.png';
import { EditBtn, PictureBtn } from "icons";
import { SearchUnivForm } from "contents/map/map";
import { useState } from "react";
import DatePicker from "contents/func/unit/datepicker";
import { getDateUnitNoTime } from "contents/func/unit";
import { useNavigate } from "react-router-dom";
import { useSetAlert } from "contents/func/toast/toast";
import { fileHandler } from "contents/func/network/imageHandler";

const cx = cn.bind(infoStyle);

const UserEdit = () =>{
    const user = useQuery({
        queryKey : ['User-Info-Key','self'],
        queryFn : ()=>AxiosIns.get('/api/restrict/user/userInfo'),
        select : (res)=>res.data,
    });
    const queryClient = useQueryClient();
    const setObject = useSetAtom(BigMenuObjectAtom);
    const setTarget = useSetAtom(MenuTargetAtom);
    const [profile,setProfile] = useState<String|null>(null);
    const [dateUniv , setDateUniv] = useState({date : '',univ : ''});
    const [UserObject,setUserObject] = useState<any>({
    });
    const [isPrivate,setPrivate] = useState(false);
    const [,setJoinCond] = useState({});
    const navigate = useNavigate();
    const setCond = (type : string,string : string|null,e? : any)=>{
        if (type) {setJoinCond(prev=>{return {...prev , [type] : string}})}
        if (e?.target?.style) if (string) {e.target.style.borderColor = 'var(--theme-red-color)';} else {e.target.style.borderColor = 'var(--theme-second-color-rgb)';}
    };
    const Alert = useSetAlert();
    
    const inputResize = (e : any) => {
        e.target.style.height = "";
        e.target.style.height = e.target.scrollHeight + 'px';
    }
    const setInput = (value : any,type : string) => {
        setUserObject((p : any)=>{return{...p,[type] : value}});
    }

    const editMutation = useMutation({
        mutationFn : ()=>AxiosIns.patch("/api/restrict/user/userInfoEdit",{
            ...(UserObject?.nickName&&{"nickname" : UserObject?.nickName}),
            ...(UserObject?.phone&&{"phone" : UserObject?.phone}),
            ...((domain.mail&&domain.domain)&&{"email" : (domain.mail + domain.domain)}),
            ...(dateUniv.date&&{"birth_date" : dateUniv.date}),
            ...(UserObject?.gender&&{"gender" : UserObject?.gender}),
            ...(isPrivate&&{"isPrivate" : isPrivate}),
            ...(profile&&{"profile" : profile}),
        }),
        mutationKey : ['User-Edit-Key'],
        onSuccess : ()=>{
            queryClient.invalidateQueries(['User-Info-Key','self']);
            Alert("수정이 완료되었어요");
            navigate(-1);
        },
    });

    const emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    const [domain,setDomain] = useState({mail : '' , domain : ''});

    return(
        <div className={cx("editForm","flex-row","row-container")}>
            <p className={cx("editTitle")}>프로필</p>
            <div className={cx("editPart","flex-column")}>
                <textarea rows={1} maxLength={120} placeholder="나에 대한 한마디를 추가해보세요"
                onInput={inputResize} className={cx("contentAboutMe","editAboutMeInput")}
                defaultValue={user?.data?.about_me}/>
                <div className={cx("contentAboutMeBorder")}></div>
                <CustomLabel htmlFor={cx("editPicInput")} className={cx("prof-container-big","flex-column")}>
                    <PictureBtn className={cx("editPicIcon")}></PictureBtn>
                    <CustomImg className = {cx("contentProfile")} src={profile||user?.data?.profile} altLink={TempImg}/>
                </CustomLabel>
                <input hidden id={cx("editPicInput")} type = "file" className={cx("join-profile-input")}
                onChange={async(e)=>await fileHandler({
                    event : e,
                    endPoint : '/serve/file/profile',
                    column : 'file',
                    onSuccess : (res : any)=>setProfile(res.fileName)
                })} accept="image/*"></input>
                <input className={cx("editInput",'editUsername')} onChange={(e)=>setInput(e.target.value,'nickName')} defaultValue={user?.data?.nickname}/>
            </div>
            <hr/>
            <p className={cx("editTitle")}>정보</p>
            <div className={cx("editPart","flex-column")}>
                <div className={cx("DatePicker","editInput",'flex-row')}>
                    <p className={cx("DatePickerCurrent")}>생년월일 : {dateUniv.date||getDateUnitNoTime(new Date(user?.data?.birthDate))}</p>
                    <CustomBtn className={cx("DateSelButton",'flex-row')} onClick={(e)=>{setTarget(e); setObject(
                        <DatePicker size={{height : '100%' , width : '100%'}}
                        format={{year : '년' , month : '월' , date : '일'}}
                        cb={(sel : any)=>{
                            setObject(null);
                            setInput(sel,'birthDate');
                            setDateUniv((prev)=>{return {...prev,date : getDateUnitNoTime(sel).toString()}});
                            }}
                        />
                    )}}>
                        <EditBtn width = '24' height = '24'/>
                    </CustomBtn>
                </div>
                <div className={cx("flex-row")}>
                    <p>성별</p>
                    <select className={cx("editInput")} onChange={(e)=>{setInput(e.target.value,'gender')}}>
                        <option defaultChecked = {(user?.data?.gender === "M")} value = 'M'>남</option>
                        <option defaultChecked = {(user?.data?.gender === "F")} value = 'F'>여</option>
                        <option defaultChecked = {(user?.data?.gender === "O")} value = 'O'>해당없음</option>
                    </select>
                </div>
                <input
                className={cx("editInput")}
                placeholder="전화번호"
                maxLength={15}
                defaultValue={user?.data?.phone}
                required type = "text" pattern="[0-9]+"
                onChange={(e)=>{setInput(e.target.value,'phone')}}
                onInput={(e : any)=>{e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');}}
                onBlur={(e)=>{if (e.target.value.length < 1) setCond('Error',' ',e); else setCond('Error',null,e);}}>
                </input>
                <div className={cx("flex-row","join-email-container")} style={{width : '90%' , maxWidth:'480px'}}>
                    <input className={cx("editInput")} defaultValue={user?.data?.email?.split("@")[0]} required type = "text" onChange={(e)=>{setDomain(prev=>{return {...prev,mail : e.target.value}})}} placeholder="이메일" onBlur={(e)=>{if (emailRegex.test(domain.mail + domain.domain)) {setCond('Error',null,e); setInput(domain.mail + domain.domain,'email')}else{setCond('Error',' ',e);}}}></input>
                    @
                    <select className={cx("editInput")} onChange = {async(e)=>setDomain(prev=>{return {...prev,domain : e.target.value}})}>
                        <option value=''>직접 입력</option>
                        <option defaultChecked={(user?.data?.email?.split("@")[1] === "gmail.com")} value='@gmail.com'>gmail.com</option>
                        <option defaultChecked={(user?.data?.email?.split("@")[1] === "naver.com")} value='@naver.com'>naver.com</option>
                        <option defaultChecked={(user?.data?.email?.split("@")[1] === "kakao.com")} value='@kakao.com'>kakao.com</option>
                        <option defaultChecked={(user?.data?.email?.split("@")[1] === "msn.com")} value='@msn.com'>msn.com</option>
                        <option defaultChecked={(user?.data?.email?.split("@")[1] === "yahoo.com")} value='@yahoo.com'>yahoo.com</option>
                    </select>
                </div>
                <div className={cx("DatePicker","editInput",'flex-row')}>
                    <p className={cx("DatePickerCurrent")}>소속 : {dateUniv.univ||user?.data?.groups?.join("")}</p>
                    <CustomBtn className={cx("DateSelButton",'flex-row')} onClick={(e)=>{setTarget(e); setObject(
                        <SearchUnivForm cb={(e)=>{setObject(null); setDateUniv((prev)=>{return {...prev,univ : e}});}}/>
                    );}}>
                        <EditBtn width = '24' height = '24'/>
                    </CustomBtn>
                </div>
            </div>
            <hr/>
            <div className={cx("editPart","flex-column")}>
                <CustomBtn className={cx("editSubmit",'flex-row')} onClick={()=>{editMutation.mutate()}}>수정</CustomBtn>
                <CustomBtn onClick={()=>navigate(-1)}>뒤로가기</CustomBtn>
            </div>
        </div>
    )
}

export default UserEdit;