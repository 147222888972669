import { useEffect, useRef, useState } from "react";

const useIntersection = (options? : Object) =>{
    const [inView,setInview] = useState<Boolean>(false);
    const observeRef = useRef<any>();
    const intersect = new IntersectionObserver((entries,observer)=>
        entries.forEach((entry) => {
            if (entry.isIntersecting){
                setInview(true);
            } else{
                setInview(false);
            }
        },
        options,
    ));

    const ref = (el : HTMLDivElement) =>{
        observeRef.current = el;
    }

    useEffect(()=>{
        if (observeRef.current) intersect.observe(observeRef.current)
        return ()=>{
            if (observeRef.current) intersect.unobserve(observeRef.current)
        }
    },[observeRef,intersect]);

    return {ref,inView};
}

export default useIntersection;