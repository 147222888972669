import { useCallback, useEffect, useRef, useState } from "react"

export const useSwipe = (ref : any , vdistance = 128 , hdistance = 128) => {
    const scrollPoint = useRef({y : 0 , x : 0});
    const scrollEndPoint = useRef({y : 0 , x : 0});
    const [HorScrolling,setHorScrolling] = useState<number>(0);
    const [downScrolling,setDownScrolling] = useState<number>(0);
    const touchStart = (e : any) =>{
        if (e.changedTouches) scrollPoint.current['y'] = e.changedTouches[0].clientY;
        else if (e.clientY) scrollPoint.current['y'] = e.clientY;
        if (e.changedTouches) scrollPoint.current['x'] = e.changedTouches[0].clientX;
        else if (e.clientY) scrollPoint.current['x'] = e.clientX;
    }
    const touchEnd = useCallback((e : any) =>{
        if (e.changedTouches) scrollEndPoint.current['y'] = e.changedTouches[0].clientY;
        else if (e.clientY) scrollEndPoint.current['y'] = e.clientY;
        if (e.changedTouches) scrollEndPoint.current['x'] = e.changedTouches[0].clientX;
        else if (e.clientY) scrollEndPoint.current['x'] = e.clientX;
        const vpullDistance = scrollPoint.current['y'] - scrollEndPoint.current['y']
        const hpullDistance = scrollPoint.current['x'] - scrollEndPoint.current['x']
        if (Math.abs(vpullDistance) >= vdistance){
            setDownScrolling(-1 * vpullDistance / Math.abs(vpullDistance));
            scrollPoint.current['y'] = 0;
            scrollEndPoint.current['y'] = 0;
        }
        if (Math.abs(hpullDistance) >= hdistance){
            setHorScrolling(-1 * hpullDistance / Math.abs(hpullDistance));
            scrollPoint.current['x'] = 0;
            scrollEndPoint.current['x'] = 0;
        }
    },[hdistance,vdistance])
    useEffect(()=>{
        ref.current?.addEventListener('mousedown',touchStart);
        ref.current?.addEventListener('mouseup',touchEnd);
        ref.current?.addEventListener('touchstart',touchStart);
        ref.current?.addEventListener('touchend',touchEnd)
        return ()=>{
            setDownScrolling(0);
            ref.current?.removeEventListener('mousedown',touchStart);
            ref.current?.removeEventListener('mouseup',touchEnd)
            ref.current?.removeEventListener('touchstart',touchStart);
            ref.current?.removeEventListener('touchend',touchEnd)
        }
    },[]);

    return [downScrolling,setDownScrolling,HorScrolling,setHorScrolling];
}