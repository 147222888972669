import { CustomBtn, CustomLink } from "contents/func/anim/custom";
import { AxiosIns } from "contents/func/network/axios";
import { useSetAlert } from "contents/func/toast/toast";
import { CurrentUserAtom, MenuTargetAtom, pageSettingAtom, PostModalObjectAtom } from "contents/state/state";
import { CertBtn, DeleteBtn, EditBtn, UserBtn } from "icons";
import { useAtomValue, useSetAtom } from "jotai";
import { useMutation, useQueryClient } from "react-query";
import { useSearchParams } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import maniStyle from "./css/manipulate.module.css";
import cn from "classnames/bind";

const cx = cn.bind(maniStyle);

export const FeedMenu = ({id , postId} : {id : string , postId : number}) =>{
    const myId = useAtomValue(CurrentUserAtom);
    const setTarget = useSetAtom(MenuTargetAtom);
    const setPostModal = useSetAtom(PostModalObjectAtom);
    const pageSetting = useAtomValue(pageSettingAtom);
    
    return (
        <div className={cx('menuSub',"flex-column")}>
            <hr/>
            <p>이 작성자</p>
            <CustomBtn className={cx('menuSubMenu','flex-row')}>
                <CertBtn width='24' height='24' fill="inherit" stroke="inherit"/>
                <p>신고</p>
            </CustomBtn>
            <CustomLink to={`${pageSetting.userInfo}?user=${id}`} className={cx('menuSubMenu','flex-row')}>
                <UserBtn width='24' height='24' fill="inherit" stroke="inherit"/>
                <p>정보</p>
            </CustomLink>
            <hr/>
            {(myId?.userId === id)&&
            <Fragment>
                <p>내가 쓴 글</p>
                <CustomLink to = {pageSetting.write} state={{mode : 'edit',page : postId}} className={cx('menuSubMenu','flex-row')}>
                    <EditBtn width='24' height='24' fill="inherit"/>
                    <p>수정</p>
                </CustomLink>
                <CustomBtn className={cx('menuSubMenu','flex-row')} onClick={()=>{setTarget(null); setPostModal(<ContentDelete id={postId}/>);}}
                style={{color : 'var(--theme-red-color)',fill:'var(--theme-red-color)',stroke:'var(--theme-red-color)'}}>
                    <DeleteBtn width='24' height='24' fill="inherit" stroke="inherit"/>
                    <p>삭제</p>
                </CustomBtn>
            </Fragment>
            }
        </div>
    );
}

const ContentDelete = ({id} : {id : number}) => {
    const setPostModal = useSetAtom(PostModalObjectAtom);
    const [searchParams,setSearchParams] = useSearchParams();
    const page = searchParams.get("page");
    const category = searchParams.get('category');
    const ParamHandler = (type : Array<string>) => type?.map((arr)=>setSearchParams((p)=>{p.delete(arr); return p;}))
    const Alert = useSetAlert();
    const queryClient = useQueryClient();
    const delMutation = useMutation({
        mutationFn : ()=>AxiosIns.delete(`/api/restrict/user/deletePost/${id}`),
        mutationKey : ['Post-Delete-Key',id],
        onMutate : ()=>setPostModal(null),
        onSuccess : ()=>{
            queryClient.invalidateQueries(['Get-Lists-Key-Cate',category]);
            if (page) ParamHandler(['page'])
            Alert("삭제가 완료되었어요");
        }
    })

    return(
        <div className={cx("flex-column","view-delete-confirm")}>
            <div className={cx("view-delete-top")}>
                <p className={cx("view-delete-title")}>정말로 삭제하실건가요?</p>
                <p className={cx("view-delete-desc")}>삭제한 글은 복구 할 수 없어요</p>
            </div>
            <div className={cx("view-delete-bottom")}>
                <CustomBtn className={cx("view-delete-btn","flex-column")} style={{color : 'var(--theme-red-color)'}} onClick={()=>delMutation.mutate()}>네</CustomBtn>
                <CustomBtn className={cx("view-delete-btn","flex-column")} onClick={()=>setPostModal(null)}>아니요</CustomBtn>
            </div>
        </div>
    )
}