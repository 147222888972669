import { useMutation, useQueryClient } from 'react-query';
import { useSetAlert } from '../toast/toast';
import { AxiosIns } from './axios';
import { useSetAtom } from 'jotai';
import { PostModalObjectAtom } from 'contents/state/state';
import { useSearchParams } from 'react-router-dom';
import maniStyle from "contents/post/view/css/manipulate.module.css";
import cn from "classnames/bind";
import { CustomBtn } from '../anim/custom';

const cx = cn.bind(maniStyle);

export const UserLogoutConfirm = () => {
    const setPostModal = useSetAtom(PostModalObjectAtom);

    return(
        <div className={cx("flex-column","view-delete-confirm")}>
            <div className={cx("view-delete-top")}>
                <p className={cx("view-delete-title")}>로그아웃할까요?</p>
            </div>
            <div className={cx("view-delete-bottom")}>
                <CustomBtn className={cx("view-delete-btn","flex-column")} onClick={performLogout}>네</CustomBtn>
                <CustomBtn className={cx("view-delete-btn","flex-column")} onClick={()=>setPostModal(null)}>아니요</CustomBtn>
            </div>
        </div>
    )
}

export const performLogout = async () => {
    await AxiosIns.post('/api/clean/userTokenCookie',null,{withCredentials: true})
    .then((res : any)=>console.log('토큰 쿠키 제거 성공 : ' + res))
    .catch((err : any)=>console.log('토큰 쿠키 제거 실패 : ' + err))
    .finally(()=>{
        localStorage.removeItem('userToken');
        window.location.replace("/");
    })
};