import { useEffect, useRef } from 'react';
import { EventSourcePolyfill , NativeEventSource } from "event-source-polyfill";
import { CurrentUserAtom, HasAlertRefreshedAtom, pageSettingAtom} from 'contents/state/state';
import { useAtomValue, useSetAtom } from 'jotai';
import { useSetAlert } from 'contents/func/toast/toast';
import { TokenCheck } from 'contents/token/check';

export const SSEProvider = () => {
    const SetAlertRefresh = useSetAtom(HasAlertRefreshedAtom);
    const setUser = useAtomValue(CurrentUserAtom);
    const pageSetting = useAtomValue(pageSettingAtom);
    const eventSource = useRef();
    const Alert = useSetAlert();
    const EventSource = EventSourcePolyfill||NativeEventSource;
    const SSEConnect = (cb)=>{
        const token = localStorage.getItem('userToken'); 
        eventSource.current = new EventSource('/api/sse/subscribe', {
            headers: {
                'Authorization': `${token}`
            },
        });

        eventSource.current.onmessage = async function (event) {
            console.log('메세지 받음 : ',event);
            /*const jwt = event.target?.headers?.Authorization;
            if (jwt) await TokenCheck(jwt)
            .then((p)=>setUser(p))
            .catch((err)=>{
                console.log(err);
                localStorage.removeItem('userToken');
                window.location.replace(pageSetting.login);
            });*/
            cb(event.data);
        };

        eventSource.current.onerror = async function (event){
            console.log(event);
            if (event.status === 401){
                const jwt = event.target?.headers?.Authorization;
                await TokenCheck(jwt)
                .then((p)=>setUser(p))
                .catch((err)=>{
                    console.log(err);
                    localStorage.removeItem('userToken');
                    window.location.replace(pageSetting.login);
                });
            }
        }
    };

    const SSECallback = () => {
        Alert("알림이 도착했어요😃");
        SetAlertRefresh(true);
    }
    
    useEffect(()=>{
        if (!eventSource.current) SSEConnect(SSECallback);
    },[eventSource]);
};