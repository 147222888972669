import { AxiosIns } from 'contents/func/network/axios';
import { Kakao, Naver, Oauth} from 'icons';
import { useEffect} from 'react';
import { LoadingBar } from '../func/anim/loading';
import JsonData from "./oauth.json";
import { useSearchParams } from 'react-router-dom';
import { pageSettingAtom } from 'contents/state/state';
import { useAtomValue } from 'jotai';
import { AxiosResponse } from 'axios';

const NaverUrl = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${JsonData.Naver.ClientID}&redirect_uri=${JsonData.Naver.redirectURI}&state=TeamCarrerNaverLoginAPI`
const kakaoUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${JsonData.Kakao.ClientID}&redirect_uri=${JsonData.Kakao.redirectURI}&response_type=code`
const GoogleUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${JsonData.Google.ClientID}&response_type=code&access_type=offline&prompt=consent&redirect_uri=${JsonData.Google.redirectURI}&scope=https://www.googleapis.com/auth/userinfo.email`
const PopupSetting = `
popup=yes,toolbar=no,menubar=no,
width=420,height=500,left=200,top=200
`

export const OAuthSignin = () =>{
    
    const googleSocialLogin = () =>{
        console.log('Google URL:', GoogleUrl);  // URL이 올바르게 설정되었는지 확인하기 위한 로그
        window.open(GoogleUrl, "구글 로그인", PopupSetting);
    }
    
    return (
        <button onClick={()=>{googleSocialLogin()}}><img src = {Oauth} width = "42px" height = "42px"/></button>
    )
}

export const KakaoSignin = () =>{
    
    const kakaoSocialLogin = () =>{
        window.open(kakaoUrl, "카카오 로그인", PopupSetting);
    }

    return(
        <button onClick={kakaoSocialLogin}><img src={Kakao} width = "42px" height = "42px"></img></button>
    )
}

export const NaverSignin = () =>{
    
    const naverSocialLogin = () =>{
        window.open(NaverUrl, "네이버 로그인", PopupSetting);
    }

    return (
        <button onClick={naverSocialLogin}>
            <img src={Naver} width = "42px" height = "42px"/>
        </button>
    )
}

export const NaverCallback = () =>{
    const [searchParam,setSearchParam] = useSearchParams();
    const code = searchParam.get('code');
    const state = searchParam.get('state');
    const pageSetting = useAtomValue(pageSettingAtom);
    
    useEffect(()=>{
        AxiosIns.post(`${process.env.REACT_APP_PROXY_AUTH_SERVER_DOMAIN}/oauth2/callback/naver`,{
            tempCode : code,
            state : state
        })
        .then(async(res)=>{
            console.log(res.data);
            await AuthorizationSet(res,'naver').then(()=>{
                window.location.replace(pageSetting.login);
            }).catch(()=>console.log("토큰을 찾을수 없음"));
        })
        .catch((err)=>{
            console.log(err);
        })
    },[])

    return (
        <LoadingBar style={{width : '72px' , left : '50%' , top : '50%' , transform : 'translate(-50%,-50%)'}}/>
    );
}

export const GoogleCallback = () =>{
    const pageSetting = useAtomValue(pageSettingAtom);
    const url = document.location.href;
    const code = url.substring(url.indexOf("code=") + 5 , url.indexOf("&"));

    useEffect(()=>{
        if (code)
            console.log(code);
            AxiosIns.post(`${process.env.REACT_APP_PROXY_AUTH_SERVER_DOMAIN}/oauth2/callback/google`, {
                tempCode : code
            })
            .then(async(res)=>{
                console.log(res.data);
                await AuthorizationSet(res,'google').then(()=>{
                    window.location.replace(pageSetting.login);
                }).catch(()=>console.log("토큰을 찾을수 없음"));
            })
            .catch((err)=>{
                console.log(err);
            });
        return;
    },[]);

/*
    const [searchParam,setSearchParam] = useSearchParams();
    const code = searchParam.get('code');
    const setAlert = useSetAlert();


    useEffect(() => {
        if (code) {
            console.log('google code : ', code);
            AxiosIns.post('https://gcp.nodove.com/oauth2/callback/google', 
                { tempCode : code }
            )        
            .then((res) => {
                console.log(res.data);
                setAlert('로그인 성공', 'success');
            })
            .catch((err) => {
                console.error(err);
                setAlert('로그인 실패', 'error');
            });
        }
    }, [code, setAlert]);
*/


    return (
        <LoadingBar style={{width : '72px' , left : '50%' , top : '50%' , transform : 'translate(-50%,-50%)'}}/>
    );
}

export const KakaoCallback = () =>{
    const [searchParam,setSearchParam] = useSearchParams();
    const code = searchParam.get('code');
    const pageSetting = useAtomValue(pageSettingAtom);

    useEffect(()=>{
        if (code)
            AxiosIns.post(`${process.env.REACT_APP_PROXY_AUTH_SERVER_DOMAIN}/oauth2/callback/kakao`,
                {tempCode : code})
            .then(async (res)=>{
                console.log(res.data);
                await AuthorizationSet(res,'kakao').then(()=>{
                    window.location.replace(pageSetting.login);
                }).catch(()=>console.log("토큰을 찾을수 없음"));
            })
            .catch((err)=>{
                console.log(err);
            });
        return;
    },[]);

    return (
        <LoadingBar style={{width : '72px' , left : '50%' , top : '50%' , transform : 'translate(-50%,-50%)'}}/>
    );
}

export const AuthorizationSet = (res : AxiosResponse,provider : string) => new Promise<void>(
    (resolve,reject)=>{
        if (res?.data?.access_token){
            const token = res?.data?.access_token;
            localStorage.setItem('userToken',token);
            localStorage.setItem('provider',provider);
            window.close();
            resolve();
        } else{
            console.log("헤더에서 토큰을 찾을 수 없습니다");
            reject();
        }
    }
)