import styles from "./css/colRow.module.css";
import MainStyle from "contents/main/main.module.css";
import cn from "classnames/bind";
import { PropsData } from "contents/func/type/typeinter"
import { HeartBtn, MsgBtn } from "icons";
import { getUnit } from "contents/func/unit";
import { CustomBtn } from "contents/func/anim/custom";
import { MouseEventHandler, ReactNode } from "react";
import { useSetAtom } from "jotai";
import { MenuObjectAtom, MenuTargetAtom} from "contents/state/state";
import { RoleParser } from "contents/user/info/userInfo";

const cx = cn.bind(styles);

const MinimalRow = ({arr,menu,onClick,className,cStyle}
    : {arr : PropsData ,menu?: ReactNode , onClick? : MouseEventHandler<HTMLElement> ,
        className? : string , cStyle? : {writer : Object,title : Object}}) => {
    const setObject = useSetAtom(MenuObjectAtom);
    const setTarget = useSetAtom(MenuTargetAtom);

    return (
        <div className={cx('infoContainer',className,'row-container')} onClick={onClick}>
            <div className={cx('MiniRowInfoTop','flex-row')}>
                <div className={cx('MiniRowInfoWriter','flex-row')}>
                    <p className={cx('MiniRowWriter')} style={cStyle?.writer}>{arr.writerNick}</p>
                    <RoleParser role='USER'/>
                </div>
                <div className={cx("MiniRowTop","flex-row")} onClick={(e)=>{e.preventDefault(); e.stopPropagation(); setTarget(e); setObject(menu)}}>
                    <CustomBtn className={cx("MiniRowTopBtn","flex-row")}>
                        <p style={{fontWeight:'bold' , fontSize : '100%' , writingMode : "vertical-lr"}}>•••</p>
                    </CustomBtn>
                </div>
            </div>
            <div className={cx('MiniRowInfoTitle') + ' ' + cx('collectedTitle')} style={cStyle?.title}>{arr.title}</div>
            <div className={cx('collectedBottomContainer','flex-row')}>
                <div className={cx("list-row-like-btn","flex-row",'collectedStatus')}>
                    <HeartBtn width="20" height="100%"/>
                    <p className="list-row-like-count">{getUnit(arr.likeCount)||'0'}</p>
                </div>
                <div className={cx("list-row-like-btn","flex-row",'collectedStatus')}>
                    <MsgBtn width="16" height="100%"/>
                    <p className="list-row-like-count">{getUnit(arr.commentCount)||'0'}</p>
                </div>
            </div>
        </div>
    )
}

export const MinimalRowSkel = () =>{
    return (
        <div className={cx('infoContainer','row-container')}>
            <div className={cx('MiniRowInfoTop','flex-row','skel-draw')} style={{width : '50%',height : '18px',marginBottom : '2px'}}>
            </div>
            <div className={cx('MiniRowInfoTitle') + ' ' + cx('collectedTitle','skel-draw')}  style={{height : '30px',marginBottom : '2px'}}></div>
            <div className={cx('collectedBottomContainer','flex-row','skel-draw')} style={{height : '20px',marginBottom : '2px'}}>
            </div>
        </div>
    );
}

export default MinimalRow;