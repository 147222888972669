import React, { useState } from 'react';
import axios from 'axios';

const SendMessageComponent = () => {
    const [userId, setUserId] = useState('');
    const [message, setMessage] = useState('');
    const [response, setResponse] = useState('');

    const handleSendMessage = async () => {
        const token = localStorage.getItem('userToken'); // 로컬 스토리지에서 토큰 가져오기
        const headers = {
            'Authorization': `${token}`,
            'Content-Type': 'application/json'
        };

        try {
            const res = await axios.post(`/api/sse/dummyData/${userId}`, { message }, { headers });
            setResponse(`STATUS: ${res.status}`);
        } catch (error) {
            setResponse(`Error: ${error.message}`);
        }
    };

    return (
        <div>
            <h1>Send Message</h1>
            <div>
                <label>
                    User ID:
                    <input type="text" value={userId} onChange={(e) => setUserId(e.target.value)} />
                </label>
            </div>
            <div>
                <label>
                    Message:
                    <input type="text" value={message} onChange={(e) => setMessage(e.target.value)} />
                </label>
            </div>
            <button onClick={handleSendMessage}>Send</button>
            <div>
                <h2>Response</h2>
                <p>{response}</p>
            </div>
        </div>
    );
};

export default SendMessageComponent;
