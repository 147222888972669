import { ActivityBtn, AddBtn, AddBtnAlt, BookmarkBtn, CertBtn, CompanyUser, HashTagBtn, HelpBtn, HomeBtn, LeftBtn, MenuBtn, MsgBtn, NaviBtn, RightBtn, SettingBtn, SummarizeBtn, UserBtn } from 'icons';
import { Route, Routes, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import { NavLeftMenu } from 'contents/interface/navbar';
import { CateRefAtom, CollectedBooleanAtom, NavbarLeftObjectAtom, NavbarLeftTargetAtom, PostModalObjectAtom, TagRefAtom, UserInfoPageAtom, WindowStateAtom, pageSettingAtom } from 'contents/state/state';
import CatePage from '../post/cate/cate';
import { useQuery } from 'react-query';
import { AxiosIns } from 'contents/func/network/axios';
import { MyTagPage } from 'contents/func/tag/tagpage';
import navbarStyle from "./css/navbar.module.css";
import { CustomBtn, CustomLink } from 'contents/func/anim/custom';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import cn from "classnames/bind";
import { Fragment } from 'react/jsx-runtime';
import { ListHeader } from 'contents/post/list';
import WritePage from 'contents/post/write';

const cx = cn.bind(navbarStyle);

export const NavbarLeft = () =>{
    const [pageSetting,] = useAtom(pageSettingAtom);
    const user = useQuery({
        queryKey : ['User-Info-Key','self'],
        queryFn : ()=>AxiosIns.get('/api/restrict/user/userInfo'),
        select : (res)=>res.data,
    });

    return (
        <nav className = {cx("navbar-left","flex-column")}>
            <NavLeftMenu/>
            <div className={cx("only-mobile-flex",'flex-column')}>
                <Routes>
                    <Route path={pageSetting.list+'/*'} element={<ListHeader/>}></Route>
                </Routes>
            </div>
            <Routes>
                <Route path = "/*" element={<CommonNavbar/>}/>
                <Route path = {pageSetting.userInfo + '/*'} element={<UserPageNavbar/>}/>
                <Route path = {pageSetting.list + '/*'} element={<ListPageNavbar/>}/>
                <Route path = {pageSetting.write} element={<ListPageNavbar/>}/>
                <Route path = {pageSetting.tag} element={<ListPageNavbar/>}/>
                <Route path = {pageSetting.setting} element={<SettingNavbar/>}/>
            </Routes>
            <div className = {cx("navbar-menu","menu-bottom","flex-row","only-pc-flex")}>
                <CustomLink to = {pageSetting.setting} className = {cx("menu-setting-items","item1","btn-style-grey-back")}>
                    <SettingBtn width='20px' height='20px' fill='inherit' className = {cx("menu-menu-items-icon")}/>
                    <p className = {cx("menu-menu-items-text")}>설정</p>
                </CustomLink>
                <CustomLink to = {pageSetting.help} className = {cx("menu-setting-items","item2","btn-style-grey-back")}>
                    <HelpBtn width='20px' height='20px' fill='inherit' stroke='inherit' className = {cx("menu-menu-items-icon")}/>
                    <p className = {cx("menu-menu-items-text")}>도움말</p>
                </CustomLink>
            </div>
        </nav>
    );
}

const CommonNavbar = () => {
    const setObject = useSetAtom(NavbarLeftObjectAtom);
    const setTarget = useSetAtom(NavbarLeftTargetAtom);
    const Location = useLocation();
    const [pageSetting,] = useAtom(pageSettingAtom);

    return(
    <div className = {cx("navbar-menu","menu-home","flex-column","only-pc-flex","scrolling")}>
        <CustomLink to = {pageSetting.main}
        className = {cx("menu-menu-items","item1","flex-row",{linked : (Location.pathname===pageSetting.main)})} >
            <HomeBtn width='20px' height='20px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>홈</p>
        </CustomLink>
        <div className = {cx("menu-menu-title","item1","flex-column")}>
            <hr/>
            <p>피드의</p>
        </div>
        <CustomBtn className = {cx("menu-menu-items","item2","flex-row",{linked : (Location.pathname===pageSetting.category)})}
        onClick={(e)=>{setTarget(e); setObject(<CatePage/>);}}>
            <MenuBtn width='16px' height='16px' stroke='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>카테고리</p>
        </CustomBtn>
        <CustomBtn className = {cx("menu-menu-items","item3","flex-row",{linked : (Location.pathname===pageSetting.myTag)})}
        onClick={(e)=>{setTarget(e); setObject(<MyTagPage/>);}}>
            <HashTagBtn width='16px' height='16px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>해시태그</p>
        </CustomBtn>
        <div className = {cx("menu-menu-title","item1","flex-column")}>
            <hr/>
            <p>나의</p>
        </div>
        <CustomLink to = {pageSetting.bookmark}  state = {{title : '북마크' , description : '북마크로 등록 해놓으신 게시물입니다'}}
        className = {cx("menu-menu-items","item4","flex-row",{linked : (Location.pathname===pageSetting.bookmark)})}>
            <BookmarkBtn width='20px' height='16px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>북마크</p>
        </CustomLink>
        <CustomLink to = {pageSetting.map}
        className = {cx("menu-menu-items","item5","flex-row",{linked : (Location.pathname===pageSetting.map)})}>
            <NaviBtn width='20px' height='16px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>주변</p>
        </CustomLink>
    </div>
    );
}

const UserPageNavbar = () => {
    const [page,setPage] = useAtom(UserInfoPageAtom);
    const navigate = useNavigate();
    return(
    <div className = {cx("navbar-menu","menu-home","flex-column","only-pc-flex","scrolling")}>
        <CustomBtn onClick={()=>setPage(0)} className = {cx("menu-menu-items","item1","flex-row",{linked : (page == 0)})}>
            <UserBtn width='20px' height='20px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>유저 홈</p>
        </CustomBtn>
        <CustomBtn onClick={()=>setPage(1)} className = {cx("menu-menu-items","item1","flex-row",{linked : (page == 1)})}>
            <MenuBtn width='20px' height='20px' stroke='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>피드</p>
        </CustomBtn>
        <CustomBtn onClick={()=>setPage(2)} className = {cx("menu-menu-items","item1","flex-row",{linked : (page == 2)})}>
            <AddBtnAlt width='20px' height='20px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>내 구독</p>
        </CustomBtn>
        <CustomBtn onClick={()=>setPage(3)} className = {cx("menu-menu-items","item1","flex-row",{linked : (page == 3)})}>
            <ActivityBtn width='20px' height='20px' stroke='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>활동</p>
        </CustomBtn>
        <hr/>
        <CustomBtn onClick={()=>navigate(-1)} className = {cx("menu-menu-items","item5","flex-row","btn-style-grey-back")} >
            <LeftBtn width='20px' height='16px' stroke='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>뒤로가기</p>
        </CustomBtn>
    </div>
    );
}

const ListPageNavbar = () => {
    const setObject = useSetAtom(NavbarLeftObjectAtom);
    const setTarget = useSetAtom(NavbarLeftTargetAtom);
    const setPostModal = useSetAtom(PostModalObjectAtom);
    const setCateRef = useSetAtom(CateRefAtom);
    const setTagRef = useSetAtom(TagRefAtom);
    const navigate = useNavigate();
    const [pageSetting,] = useAtom(pageSettingAtom);
    const [searchParam,setSearchParam] = useSearchParams();
    const category = searchParam.get('category');

    return(
        <Fragment>
            <MobileListNavbar/>
            <div className = {cx("navbar-menu","menu-home","flex-column","only-pc-flex","scrolling")}>
                <CustomLink to = {pageSetting.main} className = {cx("menu-menu-items","item1","flex-row","btn-style-grey-back")}>
                    <HomeBtn width='20px' height='20px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
                    <p className = {cx("menu-menu-items-text")}>홈</p>
                </CustomLink>
                <div className = {cx("menu-menu-title","item1","flex-column")}>
                    <hr/>
                    <p>피드의</p>
                </div>
                <CustomBtn className = {cx("menu-menu-items","item2","flex-row","btn-style-grey-back")} 
                ref={(el)=>setCateRef(el)}
                onClick={(e)=>{
                    setTarget(e); setObject(<CatePage/>);}}>
                    <MenuBtn width='20px' height='20px' stroke='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
                    <p className = {cx("menu-menu-items-text")}>카테고리</p>
                </CustomBtn>
                <CustomBtn className = {cx("menu-menu-items","item3","flex-row","btn-style-grey-back")}
                onClick={(e)=>{setTarget(e); setObject(<MyTagPage/>);}}>
                    <HashTagBtn width='20px' height='20px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
                    <p className = {cx("menu-menu-items-text")}>해시태그</p>
                </CustomBtn>
                <CustomBtn onClick={()=>{
                    setPostModal(<WritePage/>)
                }} className = {cx("menu-menu-items","item5","flex-row","btn-style-grey-back")} >
                    <AddBtnAlt width='20px' height='20px' fill='inherit' stroke='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
                    <p className = {cx("menu-menu-items-text")}>새로운 작성</p>
                </CustomBtn>
                <div className = {cx("menu-menu-title","item1","flex-column")}>
                    <hr/>
                </div>
                <CustomBtn onClick={()=>navigate(-1)} className = {cx("menu-menu-items","item5","flex-row","btn-style-grey-back")} >
                    <LeftBtn width='20px' height='16px' stroke='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
                    <p className = {cx("menu-menu-items-text")}>뒤로가기</p>
                </CustomBtn>
            </div>
        </Fragment>
    );
}

interface usertemp{
    id : string,
    room : string,
    profile : string,
    recentMsg : string,
    recentMsgDate : string
}

const SettingNavbar = () => {
    return(
    <div className = {cx("navbar-menu","menu-home","flex-column","only-pc-flex","scrolling")}>
        <CustomLink to = {''} className = {cx("menu-menu-items","item1","flex-row","btn-style-grey-back")}>
            <p className = {cx("menu-menu-items-text")}>계정</p>
        </CustomLink>
        <CustomLink to = {''} className = {cx("menu-menu-items","item1","flex-row","btn-style-grey-back")}>
            <p className = {cx("menu-menu-items-text")}>테마</p>
        </CustomLink>
        <CustomLink to = {''} className = {cx("menu-menu-items","item1","flex-row","btn-style-grey-back")}>
            <p className = {cx("menu-menu-items-text")}>위치</p>
        </CustomLink>
        <CustomLink to = {''} className = {cx("menu-menu-items","item1","flex-row","btn-style-grey-back")}>
            <p className = {cx("menu-menu-items-text")}>피드</p>
        </CustomLink>
        <hr/>
        <CustomLink to = '..' className = {cx("menu-menu-items","item5","flex-row","btn-style-grey-back")}>
            <LeftBtn width='20px' height='18px' stroke='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>뒤로가기</p>
        </CustomLink>
    </div>
    );
}

const MobileListNavbar = () => {
    const [pageSetting,] = useAtom(pageSettingAtom);
    const Location = useLocation();
    const [collected,setCollected] = useAtom(CollectedBooleanAtom);

    return(
    <div className = {cx("navbar-menu","only-mobile-flex","menu-home","flex-column","scrolling")}>
        <div className = {cx("menu-menu-title","item1","flex-column")}>
            <hr/>
            <p>설정</p>
        </div>
        <CustomBtn className = {cx("menu-menu-items","item3","flex-row","btn-style-grey-back")}
        onClick={()=>setCollected(!collected)}>
            {(collected)?
                <Fragment>
                    <MenuBtn width='20px' height='20px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
                    <p className = {cx("menu-menu-items-text")}>피드 뷰</p>
                </Fragment>
            : 
                <Fragment>
                    <SummarizeBtn width='20px' height='20px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
                    <p className = {cx("menu-menu-items-text")}>이미지 뷰</p>
                </Fragment>  
            }
        </CustomBtn>
        <div className = {cx("menu-menu-title","item1","flex-column")}>
            <hr/>
            <p>이동</p>
        </div>
        <CustomLink className = {cx("menu-menu-items","item3","flex-row","btn-style-grey-back")}
        to={pageSetting.myTag}>
            <HashTagBtn width='20px' height='20px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>태그</p>
        </CustomLink>
        <CustomLink to = {pageSetting.map}
        className = {cx("menu-menu-items","item5","flex-row",{linked : (Location.pathname===pageSetting.map)})}>
            <NaviBtn width='20px' height='16px' fill='inherit' className = {cx("menu-menu-items-icon","flex-column")}/>
            <p className = {cx("menu-menu-items-text")}>주변</p>
        </CustomLink>
    </div>
    );
}