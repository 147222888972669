import axios, { Axios, AxiosRequestConfig } from "axios";
import { CurrentUserAtom, pageSettingAtom } from "contents/state/state";
import { decodingToken } from "contents/token/decode";
import { useAtomValue, useSetAtom } from "jotai";
import { useContext, useEffect } from "react";
import { TokenCheck } from "contents/token/check";

export const AxiosConfig : AxiosRequestConfig = {
    timeout : 10000,
    headers : {
        ...(localStorage.getItem('userToken')&&{
            Authorization : `Bearer ${localStorage.getItem('userToken')}`,
            }
        ),
        ...(localStorage.getItem('provider')&&{
                Provider : localStorage.getItem('provider'),
            }
        ),
    },
    withCredentials : true
}
export const AxiosIns = axios.create(AxiosConfig);

export const useAxiosIntercepter = () =>{
    const pageSetting = useAtomValue(pageSettingAtom);
    const setUser = useSetAtom(CurrentUserAtom);

    const onResponse = async(res : any) =>{
        console.log(res?.headers);
        if (res?.headers?.authorization){
            const jwt = res.headers.authorization;
            await TokenCheck(jwt)
            .then((res : any)=>{
                setUser(res);
            })
        }
        return res;
    }
    const onReject = async(err : any) => {
        const res = err.response;
        if (res?.headers?.authorization){
            await TokenCheck(res?.headers?.authorization)
            .then((res : any)=>setUser(res))
            .catch(async()=>{
                console.log(err);
                //localStorage.removeItem('userToken');
                //window.location.replace(pageSetting.login);
            })
        }
        if (res?.status == 401 || res?.status == 404){
            await axios.post(process.env.REACT_APP_PROXY_AUTH_SERVER_DOMAIN + "/auth/api/protected/refresh",{
                expiredToken : localStorage.getItem("userToken"),
                provider : localStorage.getItem("provider"),
            })
            .then((res)=>{
                localStorage.setItem("userToken",res?.headers?.authorization);
                window.location.reload();
            })
            .catch(()=>{
                localStorage.removeItem('userToken');
                window.location.replace(pageSetting.login);
            })
        }
        
        return Promise.reject(err);
    }

    useEffect(()=>{
        const resInterceptor = AxiosIns.interceptors.response.use(onResponse,onReject);
        return ()=>
            AxiosIns.interceptors.response.eject(resInterceptor);
        
    },[]);
}