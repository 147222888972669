import React, {MouseEventHandler, useContext , useEffect, useRef, useState } from "react";
import { commentContext } from "./view";
import { DateUnit, getUnit } from "contents/func/unit";
import { useInfiniteQuery } from "react-query";
import { AxiosIns } from "contents/func/network/axios";
import { AddBtnAlt,  HeartBtn,  HeartFillBtn, MsgBtn, RightBtn} from "icons";
import { useMutation, useQueryClient } from "react-query";
import { useInView } from "react-intersection-observer";
import { CustomBtn, CustomImg, CustomLabel, CustomLink } from "contents/func/anim/custom";
import { useSearchParams } from "react-router-dom";
import commentStyle from "./css/comment.module.css";
import cn from "classnames/bind";
import { RoleParser } from "contents/user/info/userInfo";
import TempImg from 'icons/user/logo.png';
import { BigMenuObjectAtom, CurrentUserAtom, MenuObjectAtom, MenuTargetAtom, pageSettingAtom } from "contents/state/state";
import { useAtomValue, useSetAtom } from "jotai";
import DOMPurify from "dompurify";
import { fileHandler } from "contents/func/network/imageHandler";
import useIntersection from "contents/func/hooks/intersect";
import { IntersectionOption } from "../list";
import { LoadingBar } from "contents/func/anim/loading";

const cx = cn.bind(commentStyle);

export const CommentModule = ({pageId} : {pageId? : number}) =>{
    const [part,setPart] = useState(0);
    return (
        <>
            <CommentView pageId={pageId} className={cx("scrolling")} />
            <CommentWritePage />
        </>
    );
}

export const CommentView = ({className , pageId , onTap , preview}
    : {className? : string , pageId? : number , onTap? : MouseEventHandler<HTMLElement> , preview? : number}) =>{
    const commentMax = 10;
    const [searchParams,setSearchParams] = useSearchParams();
    const scrollRef = useRef<any>();
    const page = searchParams.get('page')||pageId;
    const setObject = useSetAtom(BigMenuObjectAtom);
    const comments = useInfiniteQuery(['Get-Comment-List-Key',page],
    async({pageParam = 1})=>await AxiosIns.get(`/api/commentListByPostId/${page}/${pageParam - 1}?pageSize=${commentMax}`),{
        getNextPageParam: (lastPage, pages) => {
            if (lastPage.data['comments'].length >= commentMax) return pages.length + 1;
            else return undefined;
        },
        //enabled : Boolean(page)
    });
    const {ref,inView} = useIntersection(IntersectionOption);

    useEffect(()=>{
        if (inView&&comments.hasNextPage){
            comments.fetchNextPage();
        }
        return () => {};
    },[inView]);

    useEffect(()=>{
        if (!page) setObject(null);
    },[page]);

    return (
        <React.Fragment>
            <div className = {cx(className,"list-row-comments")} ref={scrollRef} onClick={(e)=>{if (onTap) onTap(e)}}>
                {(comments.isLoading)?
                (
                    new Array(5).fill(1).map((_arr,i)=>(
                        <CommentsSkel key={i}/>
                    ))
                ):(comments.isSuccess&&comments.data?.pages[0]?.data?.comments.length > 0)?
                    <React.Fragment>
                        {comments.data?.pages?.map((p)=>
                        (p?.data?.comments?.length > 0)
                        &&p?.data?.comments?.map((arr : any,i : number)=>(
                            <commentContext.Provider value={{arr,i}} key={i}>
                                <Comments preview={preview}/>
                            </commentContext.Provider>
                        ))).slice(0,preview)}
                        {(comments.hasNextPage)&&<div ref={ref} className={cx("flex-column")} style={{width : '100%' , height : '96px'}}>
                            <LoadingBar style={{position : 'static',transform : 'none' , height : '100%'}}/>
                        </div>} 
                    </React.Fragment>
                :(<div style={{width : '100%', height : "50dvh" , justifyContent : "center"}} className={cx("flex-column")}>
                    <p style={{fontSize : '18px' , fontWeight:'bold'}}>작성한 댓글이 없어요.</p>
                    <p style={{fontSize : '16px' , fontWeight:'normal'}}>첫 댓글의 주인공이 되어보세요!</p>
                </div>)
                }
            </div>
        </React.Fragment>
    );
}

export const CommentWritePage = () =>{
    const [commentText,setCommentText] = useState('');
    const queryClient = useQueryClient();
    const [searchParams,setSearchParams] = useSearchParams();
    const setObject = useSetAtom(BigMenuObjectAtom);
    const setTarget = useSetAtom(MenuTargetAtom);
    const page = searchParams.get('page');

    const CommentPost = async(text : string)=>
        await AxiosIns.post('/api/restrict/user/commentWrite',{
        post_id : page , comment : text
    })

    const CommentWrite = useMutation({
        mutationKey : ['PostCommentContentKey'],
        mutationFn : CommentPost,
        onSuccess : ()=>{
            queryClient.invalidateQueries(['Get-Comment-List-Key',page]);
            setCommentText('');
            setTarget(null);
        }
    })
    const CommentUpload = (url : String) =>{
        const withTag = `<img src="${url}"/>`
        CommentWrite.mutate(withTag);
    }


    return (
        <div className = {cx("write-container","flex-row","row-container")}>
            <input type = "file" id = {cx("commentUpload")} multiple accept="image/*"
            onChange={e=>fileHandler({
                event : e,
                endPoint : '/serve/file/images',
                column : 'file',
                onSuccess : (res : Array<string>)=>{CommentUpload(res[0]); setTarget(null);}
            })} hidden/>
            <CustomLabel htmlFor={cx("commentUpload")} className={cx("write-submit","btn-style-grey-back","flex-row")}>
                <AddBtnAlt width = '24px' height = '100%'/>
            </CustomLabel>
            <input type="text" value={commentText} className={cx("write-input")} onChange={(e)=>setCommentText(e.target.value)}
            onKeyUp={(e)=>{if (e.key==="Enter" && commentText) CommentWrite.mutate(commentText)}}/>
            <CustomBtn className={cx("write-submit","btn-style-grey-back","flex-row")} onClick={()=>{if (commentText) CommentWrite.mutate(commentText)}}>
                <MsgBtn width = '20px' height = '100%'/>
            </CustomBtn>
        </div>
    );
}

export const Comments = ({preview} : {preview? : number}) =>{
    const {arr} = useContext(commentContext);
    const [hasLiked,SetHasLiked] = useState(false);
    const [searchParam,setSearchParam] = useSearchParams();
    const page = searchParam.get('page');
    const pageSetting = useAtomValue(pageSettingAtom);
    const myId = useAtomValue(CurrentUserAtom);
    const LikeIncrement = useMutation(()=>AxiosIns.post(
    '/api/comment/LikeCount/increment'
    ,{
        idOfUser : myId?.userId,
        receiverId : arr.writerId,
        receiverNick : arr.writerNick,
        postId : Number(page),
        commentId : arr.id,
        commentContent : arr.comment,
        currentLikeCount : 0
    }),{
        onMutate : ()=>{SetHasLiked(e=>!e); }
    });


    return (
        (arr)&&
        <div className={cx("comment",arr.id,'flex-row')}>
            <div className={cx("commentsLeft","flex-column")}>
                <div className={cx("row-user-info","flex-row")}>
                    <CustomLink className={cx("row-writer",'flex-row')}>
                        <div className = "prof-container-minimal">
                            <CustomImg src="" alt="" altLink={TempImg}/>
                        </div>
                        {arr.writer}
                        <RoleParser role = "USER"/>
                    </CustomLink>
                </div>
                <div className={cx("row-contents",'flex-column')}
                dangerouslySetInnerHTML={{__html : DOMPurify.sanitize(arr.comment)}}/>
                {(!preview)&&<CustomBtn className={cx("row-bottom-container","flex-row")}>
                    <div className={cx("row-write",'flex-row')}>
                        답글 {arr.replies?.length}개 보기
                        <RightBtn width = "16" height = "10"/>
                    </div>
                </CustomBtn>}
            </div>
            <div className={cx("commentsRight",'flex-column')}>
                {(!preview)&&<CustomBtn className={cx("commentsLikeBtn","flex-column")} onClick = {()=>LikeIncrement.mutate()}>
                    {(hasLiked)?
                    <HeartBtn width="20px" height="20px"/>:<HeartFillBtn width="20px" height="20px"/>}
                    <p className={cx("commentLikeCount")}>{getUnit(arr.likeCount + Number(hasLiked))||'0'}</p>
                </CustomBtn>}
                <div className={cx("row-regdate")}>{DateUnit(arr.createdAt)}</div>
            </div>
        </div>
    );
}

export const CommentsSkel = () =>{
    return (
        <div>
            <div className={cx("row-user-info","flex-row","skel-draw")} style={{width : '25%',height :'20px'}}>
                <div className={cx("row-writer")}> </div>
            </div>
            <div className={cx("row-contents","skel-draw")} style={{height :'20px'}}> </div>
            <div className={cx("row-bottom-container","flex-row","skel-draw")} style={{height :'18px'}}>
                <div className={cx("row-write")}> </div>
                <div className={cx("row-regdate")}> </div>
            </div>
            <hr/>
        </div>
    );
}