import { CollectedMap} from "contents/post/list"
import { TagList } from "contents/func/tag/taglist";
import { getDateUnitNoTime} from "contents/func/unit";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ContentStateAtom, CurrentUserAtom, MenuObjectAtom, MenuTargetAtom,PostModalObjectAtom,UserInfoPageAtom, WindowStateAtom, pageSettingAtom } from "contents/state/state";
import React, { createContext, Fragment, useContext, useEffect, useState} from "react";
import { useInfiniteQuery, useQueries, useQuery, useQueryClient } from "react-query";
import { AxiosIns } from "contents/func/network/axios";
import tagStyles from "contents/func/tag/tags.module.css";
import DynamicMeta from "dynamicMeta";
import { Section } from "contents/func/section/section";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import infoStyle from "../css/info.module.css";
import cn from "classnames/bind";
import { CustomBtn, CustomImg, CustomLink } from "contents/func/anim/custom";
import { CertBtn, CloseBtn, CompanyUser, EditBtn, HashTagBtn, MsgBtn, NormalUser, PictureBtn, SettingBtn, ShareBtn, UserBtn } from "icons";
import TempImg from 'icons/user/logo.png';
import { ContentComp } from "contents/main/main";
import { take } from "lodash"
import { useSetAlert } from "contents/func/toast/toast";
import useShare from "contents/func/hooks/share";
import { performLogout, UserLogoutConfirm } from "contents/func/network/close";
import { ShareAPI } from "contents/share/share";
import { MobileView } from "contents/interface/mobile";

const cx = cn.bind(infoStyle);
const cs = cn.bind(tagStyles);
const userContext = createContext<any>(undefined);

type RoleType = "USER" | "admin" | "company"
export const RoleParser = ({role} : {role : RoleType}) =>{
    return (
        <div className="flex-row CommonWriter">
            {{
                'USER': 
                <NormalUser width = "12" height = "100%"/>
                ,
                'admin' : 
                <CompanyUser width = "12" height = "100%"/>
                ,
                'company' : 
                <CompanyUser width = "12" height = "100%"/>
            }[role]}
        </div>
    );
}

const CertPage = () =>{
    const {user} = useContext(userContext);
    
    return (
        <div className={cx('certificationPage','flex-row')}>
            <div className={cx('cert-info-container','row-container-shadowed')}>
                <p className={cx('cert-info-title')}>정보</p>
                <div className={cx('info-container','flex-column')}>
                    <div className="flex-row">닉네임 : <p className={cx('info-row')}>{user?.data?.nickname}</p></div>
                    <div className="flex-row">ID : <p className={cx('info-row')}>@{user?.data?.userId}</p></div>
                    <div className="flex-row">계정 유형 : <p className={cx('info-row')}>{(user?.data?.role == 'USER')?'일반 유저':'관리자'}</p></div>
                    <div className="flex-row">생년월일 : <p className={cx('info-row')}>{getDateUnitNoTime(new Date(user?.data?.birthDate))}</p></div>
                    <div className="flex-row">계정 공개 : <p className={cx('info-row')}>{(user?.data?.private)?'예':'아니오'}</p></div>
                </div>
            </div>
            <div className={cx('cert-info-container','row-container-shadowed')}>
                <p className={cx('cert-info-title')}>자격증 정보</p>
                <div className={cx('info-container','flex-row')}>
                    {user?.data?.certifications?.map((arr : string,i : number)=>(
                        <div className={cx("contentCert",'flex-column') + ' ' + cs('Tags','row-container')} key={i}>{arr}</div>
                    ))}
                </div>
            </div>
            <div className={cx('score-info-container','row-container-shadowed')}>
                <p className={cx('score-info-title')}>기록 성적</p>
                <div className={cx('info-container','flex-row')}>
                    {user?.data?.score?.map((arr : string,i : number)=>(
                        <div className={cx("contentCert",'flex-column') + ' ' + cs('Tags','row-container')} key={i}>{arr}</div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export const UserCard = ({user} : {user : any}) =>{
    const setObject = useSetAtom(MenuObjectAtom);
    const setTarget = useSetAtom(MenuTargetAtom);
    const pageSetting = useAtomValue(pageSettingAtom);
    const navigate = useNavigate();
    const myId = useAtomValue(CurrentUserAtom);
    const [searchParam,setSearchParam] = useSearchParams();
    const userParam = searchParam.get('user');
    const setPostModal = useSetAtom(PostModalObjectAtom);
    console.log(user?.data?.userId,myId?.userId,userParam);

    return (
        <div className = {cx("contentUser",'row-container-shadowed',"flex-row")}>
            <div className={cx("contentInfo1","flex-column")}>
                <div className={cx('contentInfoBtn','only-pc')}>
                    <CustomBtn className={cx('flex-column',"only-mobile")} onClick={(e)=>{setTarget(e); setObject(<UserEtc/>)}}>
                        <p style={{fontWeight:'bold' , fontSize : '100%' , writingMode : "vertical-lr"}}>•••</p>
                    </CustomBtn>
                </div>
                <div className={cx("contentAboutMe")}>{user?.data?.about_me||'나에 대한 한마디를 추가해보세요'}</div>
                <div className={cx("contentAboutMeBorder")}></div>
                <div className={cx("prof-container-big","flex-column")}>
                    <CustomImg className = {cx("contentProfile")} src={user?.data?.profile||null} altLink={TempImg}/>
                </div>
                <div className={cx("contentUsername")}>{user?.data?.nickname}</div>
                <div className={cx("contentId",'flex-row')}>
                    {user?.data?.groups.toString()||'소속 없음'}
                    <RoleParser role={user?.data?.role||"USER"}/>
                </div>
                <div className={cx("contentRegdate")}>{getDateUnitNoTime(new Date())} 가입</div>
                <div className={cx("flex-row","contentUserBtnContainer","only-pc-flex")}>
                    <CustomBtn className={cx("flex-row","contentUserBtn")} onClick={()=>{}}>
                        <MsgBtn width = '16' height="16"/>
                        <p className={cx("contentUserBtnLabel")}>메신저</p>
                    </CustomBtn>
                    <CustomBtn className={cx("flex-row","contentUserBtn")} onClick={(e)=>{
                        setTarget(e);
                        setObject(<MobileView><ShareAPI title = {`@${user?.data.userId}`} text = {`@${user?.data.userId}`} targetUrl={process.env.REACT_APP_BACK_SERVER_DOMAIN + pageSetting.userInfo + '?user=' + user?.data?.userId}/></MobileView>);
                    }}>
                        <ShareBtn width = '16' height="16"/>
                        <p className={cx("contentUserBtnLabel")}>프로필 공유</p>
                    </CustomBtn>
                    {
                        (user?.data?.userId == myId?.userId || userParam == null)&&
                        (
                            <Fragment>
                                <CustomBtn className={cx("flex-row","contentUserBtn")} onClick={()=>navigate(pageSetting.userEdit)}>
                                    <SettingBtn width = '16' height="16"/>
                                    <p className={cx("contentUserBtnLabel")}>설정</p>
                                </CustomBtn>
                                <CustomBtn className={cx("flex-row","contentUserBtn")} onClick={()=>{setTarget(null); setPostModal(<UserLogoutConfirm/>);}}>
                                    <CloseBtn width = '16' height="16" fill="var(--theme-red-color)"/>
                                    <p className={cx("contentUserBtnLabel")}>로그아웃</p>
                                </CustomBtn>
                            </Fragment>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

const UserPage = () =>{
    const Location = useLocation();
    const navigate = useNavigate();
    const [page,setPage] = useAtom(UserInfoPageAtom);
    const windowState = useAtomValue(WindowStateAtom);
    const [pageSetting,] = useAtom(pageSettingAtom);
    const [searchParam,setSearchParam] = useSearchParams();
    const userParam = searchParam.get('user');
    const myId = useAtomValue(CurrentUserAtom);
    const [userId,setUserId] = useState<String|null>();
    const Alert = useSetAlert();
    const pageMax = 15

    useEffect(()=>{
        if (myId) {
            setUserId(userParam||myId?.userId);
        }
        return ()=>{
            setUserId(null);
        }
    },[myId])
    
    const user = useQuery({
        enabled : Boolean(userId),
        queryKey : ['User-Info-Key',userId||"self"],
        queryFn : ()=>AxiosIns.get('/api/restrict/user/userInfo' + (userId?`?userId=${userId}`:'')),
        select : (res)=>res.data,
        
    });
    const list = useInfiniteQuery({
        enabled : (page == 1),
        queryKey : ['Get-List-User-Feed-Key',userId||user?.data?.userId],
        queryFn : async({pageParam = 1})=>await AxiosIns.get(`/api/getPostListByUserInfo/${userId||user?.data?.userId}/${pageParam - 1}?pageSize=${pageMax}`),
        getNextPageParam: (lastPage, pages) => {
            if (lastPage?.data?.length >= pageMax) return pages.length + 1;
            else return undefined;
        },
        onError : (err)=>{
            console.log(err);
            Alert('서버에 오류가 생겼어요😢')
        },
        
    });

    return (
        <div className = {cx("contentContainer",'flex-row')}>
            <DynamicMeta title={'@' + user.data?.userId||''} description={user.data?.nickname + '님의 유저정보 페이지입니다'||''} image={user.data?.profile} size={{width: "540px",height: "540px"}} url={Location.pathname} />
                <UserCard user={user}/>
            <Section className={cx("only-mobile-flex")} menus={['홈','피드','활동','정보']} callback={(_e : Event,i : number)=>{setPage(i)}}/>
            <userContext.Provider value={{user}}>
                {
                    {
                        0 : <UserMain/>,
                        1 : <CollectedMap row={(windowState.mobile?3:4)} list = {list} className = {cx("contentCollected","flex-row")}/>,
                        2 : <></>,
                        3 : <CertPage/>
                    }[page]
                }
            </userContext.Provider>
        </div>
    )
}

export const UserEtc = () =>{
    const [searchParam,setSearchParam] = useSearchParams();
    const navigate = useNavigate();
    const pageSetting = useAtomValue(pageSettingAtom);
    const userParam = searchParam.get('user');
    const myId = useAtomValue(CurrentUserAtom);
    const user = searchParam.get('user');
    const {copyUrl} = useShare();
    const setTarget = useSetAtom(MenuTargetAtom);
    const setPostModal = useSetAtom(PostModalObjectAtom);

    return (
        (myId?.userId)?
        (!userParam || myId?.userId == userParam)?
        (
            <div className={cx('smallMenuSub','flex-column')}>
                <CustomBtn className={cx('smallMenuSubMenu','flex-row')} onClick={()=>copyUrl(process.env.REACT_APP_BACK_SERVER_DOMAIN + pageSetting.userInfo + `?user=${user||myId.userId}`)}>
                    <ShareBtn width="12" height="12"/>
                    링크 복사
                </CustomBtn>
                <CustomBtn className={cx('smallMenuSubMenu','flex-row')} onClick={()=>{setTarget(null); setPostModal(<UserLogoutConfirm/>);}}>
                    <UserBtn width="12" height="12"/>
                    로그아웃
                </CustomBtn>
            </div>
        )
        :(
            <div className={cx('smallMenuSub','flex-column')}>
                <p>이 유저</p>
                <CustomBtn className={cx('smallMenuSubMenu','flex-row')}>
                    <MsgBtn width="12" height="12"/>
                    말 걸기
                </CustomBtn>
                <CustomBtn className={cx('smallMenuSubMenu','flex-row')}>
                    <CertBtn width="12" height="12"/>
                    페이지 복사
                </CustomBtn>
                <CustomBtn className={cx('smallMenuSubMenu','flex-row')} style={{fill : 'var(--theme-red-color)',color : 'var(--theme-red-color)'}}>
                    <CloseBtn width="12" height="12"/>
                    차단
                </CustomBtn>
            </div>
        )
    :<></>);
}

const UserMain = () =>{
    const [searchParam,setSearchParam] = useSearchParams();
    const userParam = searchParam.get('user');
    const myId = useAtomValue(CurrentUserAtom);
    const userId = userParam||myId?.userId;
    const page = useAtomValue(UserInfoPageAtom);
    const Alert = useSetAlert();
    const [myTags,setMyTags] = useState<any>([]);
    const maximum = 10;
    const list = useQuery({
        enabled : (page == 0),
        queryKey : ['Get-List-User-Feed-Key',userId||'self'],
        queryFn : async()=>await AxiosIns.get(`/api/getPostListByUserInfo${(userId)?`/${userId}`:""}/0?pageSize=${maximum}`),
        onError : (err)=>{
            console.log(err);
            Alert('서버에 오류가 생겼어요😢')
        },
        select : (res)=>res.data,
    });
    /*console.log(mytags);
    const tags = useQueries(
        (mytags?.data?.length>0)?
        mytags?.data?.map((arr,i)=>({
            queryKey : ['Get-Main-Content-List-Key','Main-Tag-List-Key',arr],
            queryFn : () => fetch(mytags?.data[i]),
        }))
        :new Array(5).fill(1).map(()=>{})
    )
    const fetch = async(tag) => {
        console.log(tag);
        return await AxiosIns.get(`/api/getPostListByTag/` + tag + '/0?pageSize=1&page=0')
        .then(res=>res.data)
        .catch(err=>console.log(err))
    }*/
    const [tags,setTags] = useState<any>([]);

    useEffect(()=>{
       AxiosIns.get("/api/restrict/user/tagsFindByUserId")
       .then(async({data})=>{
            const result = take(data,maximum)
            setMyTags(result);
            setTags([]);
            for await(let i of result){
                AxiosIns.get(`/api/getPostListByTag/` + i + `/0?pageSize=${maximum}&page=0`)
                .then((res)=>{
                    setTags((p : Array<any>)=>[...p,res]);
                }).catch((err)=>{
                    console.log(err);
                })
            }
       })
       .catch((err)=>{
            console.log(err);
            Alert('서버에 오류가 생겼어요😢')
       })
    },[]);

    return (
        <div className={cx("flex-column","mainCotnainer")}>
            <ContentComp list = {list} title={'작성한 피드'}/>
            <ContentComp list = {list} title={'좋아요'}/>
            {tags?.map((arr : Array<any>,i : number)=>(
                <ContentComp list = {arr} title={`#${myTags[i]}`}/>
            ))}
        </div>
    );
}

export default UserPage;