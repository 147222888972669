import { Fragment, MouseEventHandler, ReactNode, useCallback, useEffect, useRef } from "react";
import BtnStyle from "./css/button.module.css";
import { useState, forwardRef } from "react";
import { Link, RelativeRoutingType } from "react-router-dom";
import { CloseBtn} from "icons";
import cn from "classnames/bind"
import Lottie from "lottie-web";
const AnimJson = require("icons/common/loading.json");

const cx = cn.bind(BtnStyle);
export const CustomBtn = forwardRef(({className,children,onClick,style} : {className? : string ,children? : ReactNode , onClick? : MouseEventHandler<HTMLDivElement>, style? : Object},ref : any) =>{
    const [click,setClick] = useState<Boolean>(false);
    
    return(
        <div role="button" className={cx(className,'CustomBtn',{actived : click})} style={style} ref={ref}
        onTouchStart={()=>{setClick(true)}} onTouchEnd={()=>{setClick(false)}} onTouchCancel={()=>{setClick(false)}}
        onClick={onClick} onMouseDown = {()=>{setClick(true)}}
        onMouseUp={()=>{setClick(false)}} onMouseLeave={()=>{setClick(false)}}>
            {children}
        </div>
    )
});

export const CustomLink = forwardRef(({to,className,children,state,relative,style} : {to? : string , className? : string , children? : ReactNode , state? : Object,relative? : RelativeRoutingType|null,style? : object},ref : any) => {
    const [click,setClick] = useState<Boolean>(false);

    return(
        <Link to = {to||''} style={{...style}} className={cx(className,'CustomBtn',{actived : click})} ref={ref} relative={relative||'path'}
        onTouchStart={()=>{setClick(true)}} onTouchEnd={()=>{setClick(false)}} onTouchCancel={()=>{setClick(false)}}
        onMouseDown = {()=>{setClick(true)}} onMouseUp={()=>{setClick(false)}}
        onMouseLeave={()=>{setClick(false)}} state={state}>
            {children}
        </Link>
    )
});

export const CustomLabel = forwardRef(({className,children,htmlFor,style} : {className? : string , children : ReactNode , htmlFor : any , style? : object},ref : any) => {
    const [click,setClick] = useState<Boolean>(false);
    return(
        <label htmlFor={htmlFor} className={cx(className,'CustomBtn',{actived : click})} ref={ref}
        onTouchStart={()=>{setClick(true)}} onTouchEnd={()=>{setClick(false)}} onTouchCancel={()=>{setClick(false)}}
        onMouseDown = {()=>{setClick(true)}} onMouseUp={()=>{setClick(false)}}
        onMouseLeave={()=>{setClick(false)}} style={{...style}} onClick={e=>{e.stopPropagation();}}>
            {children}
        </label>
    )
});

export const CustomClose = ({onClick} : {onClick? : Function}) => {
    return (
        <CustomBtn className={cx('closeBtn','HoverCloseBtn',"row-container",'btn-style-with-back','flex-column')} onClick={(e)=>{e.stopPropagation(); e.preventDefault(); if(onClick) onClick();}}>
            <CloseBtn width='16px' height='16px' fill="var(--theme-back-color-strong)"/>
        </CustomBtn>
    )
}


export const CustomImg = ({src,alt,width,height,altLink,className,intersection = true,onClick}
    : {src? : string , alt? : string , width? : string ,
        height? : string,altLink? : string,className? : string,
        onClick? : MouseEventHandler<HTMLDivElement>,intersection? : boolean
    }) => {
    const [visible , setVisible] = useState<boolean>(!intersection);
    const observer = useRef<IntersectionObserver>();
    const imgRef = useRef<HTMLImageElement>(null);
    const loadingRef = useRef<any>(null);
    
    const intersect = useCallback((entries : IntersectionObserverEntry[],io : IntersectionObserver) =>{
        entries.forEach((entry)=>{
            if (entry.isIntersecting){
                const image = new Image();
                image.src = src!;
                image.onload = () =>{
                    setVisible(true);
                }
                if (observer.current&&imgRef.current) observer.current?.unobserve(imgRef.current!);
            }
        })
    },[src]);

    useEffect(()=>{        
        const lottie = Lottie.loadAnimation({
            container : loadingRef.current,
            renderer : 'svg',
            loop : true,
            autoplay : true,
            animationData : AnimJson
        });
        if (intersection) {
            observer.current = new IntersectionObserver(intersect,{rootMargin : '0px'});
            if(imgRef.current) {
                observer.current.observe(imgRef.current);
            }
        }
        
        return ()=>{
            if (intersection) observer.current?.disconnect();
            lottie?.destroy();
        }
    },[intersect,intersection]);
    
    return (
        <div ref={imgRef} style={{width : '100%',height : '100%'}}>
            {(src&&visible)?
            (<img
            className={className} onClick={onClick} decoding="async"
            loading = 'lazy' src={visible?src:''} alt={alt||''} 
            width={width||'100%'} height={height||'100%'}/>)
        :
            (<div style={{
                width : '100%' , height : '100%' , backgroundColor : "var(--theme-grey-color)"
            }}>
                <div style={{width : '65%',height : '100%' , margin:'auto'}}/>
            </div>)}
        </div>
    );
}

export const CustomToggle = ({on,onChange,style,state}
    : {on : boolean,onChange : ()=>void , style : Object , state? : Array<String>}) => {

    const stateStr = state||['네','아니오'];
    
    return (
        <Fragment>
             <input type="checkbox" id={cx("toggle")} checked = {on} onChange={onChange} hidden></input> 
             <label htmlFor={cx("toggle")} style={style} className={cx("toggleSwitch")}>{(on)?stateStr[0]:stateStr[1]}</label>
        </Fragment>
    );
}