import imageCompression from "browser-image-compression";

import { AxiosConfig, AxiosIns } from "./axios";
import { TypeCheck } from "../type/typeinter";
import axios, { AxiosRequestConfig } from "axios";

const uploadConfig : AxiosRequestConfig = {
    withCredentials : true,
    headers : {
        ...AxiosConfig.headers,
        'Content-Type': 'multipart/form-data',
    },
}

/**
 * 
 * @param {*} e 파일의 Event
 * @param {*} pos 업로드할 파일의 위치
 * @param {*} column 업로드할 파일의 속성명
 * @param {*} cb 콜백 함수 (파일 링크 반환)
 * @returns 
 */
const ImageCompression = (file : File) =>
    new Promise(async(resolve,reject)=>{
    const options = {
        maxSizeMB : 0.2,
        maxWidthOrHeight : 1920,
        useWebWorker : true,
    }
    try{
        const compressedFile = await imageCompression(file, options);
        imageCompression.getDataUrlFromFile(compressedFile)
            .then((res)=>resolve(res))
            .catch((err)=>reject(err));
    } catch(err){
        console.log(err);
    }
})

const dataURLtoFile = (dataurl : string, fileName : string) => {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)![1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], fileName, {type:mime});
}

export const fileHandler = async(
    {event , endPoint , column = "file" , onSuccess , onMutate , onError} : 
    {
        event : any,
        endPoint : string,
        column? : string,
        onSuccess? : (variables: Array<string>) => any
        onMutate? : (variables: string[]) => any
        onError? : (variables: Event) => any
    }
)=>{
    const array : string[] = new Array();
    const TargetFiles : File[] = [...(event.target.files||event.dataTransfer.files)];
    const formData = new FormData();
    const link = process.env.REACT_APP_FILE_PREFIX + endPoint;

    for (var key in TargetFiles){
        if (TargetFiles[key].type.includes("image")){
            await ImageCompression(TargetFiles[key])
            .then((res : any)=>{
                array.push(res);
                const changedFile = dataURLtoFile(res,TargetFiles[key].name);
                formData.append(column,changedFile);
            }).catch((err)=>console.log(err));
        } else {
            formData.append(column, TargetFiles[key]);
        }
    }

    if(onMutate) onMutate(array);
    
    await AxiosIns.post(link,formData)
    .then((res : any)=>{
        if (onSuccess&&TypeCheck({type : "Array",value : res})) onSuccess(res.data)
    })
    .catch((e : Event)=>{
        console.log(e);
        if(onError) onError(e);
    });
    return;
}