export const DateUnit = (input : string) => {
    try{
        const date : any = new Date(input);
        const nowDate : any = new Date();
        const getMonthBugFix = date.getMonth() + 1;
        const nowGetMonthBugFix = nowDate.getMonth() + 1;

        if (date > nowDate){return "Err";}
        return ((nowDate.getFullYear() <= date.getFullYear())?
        (nowGetMonthBugFix <= getMonthBugFix)?
        (nowDate.getDate() <= date.getDate())?
        (nowDate.getHours() <= date.getHours())?
        (nowDate.getMinutes() <= date.getMinutes())?
        (nowDate.getSeconds() <= date.getSeconds())?
        "0 초"
        :(nowDate.getSeconds() - date.getSeconds()) + "초"
        :(nowDate.getMinutes() - date.getMinutes()) + "분"
        :(nowDate.getHours() - date.getHours()) + "시간"
        :(nowDate.getDate() - date.getDate()) + "일"
        :((nowDate - date)/(1000*60*60*24) > dayMax(date.getFullYear(),date.getMonth()))?(nowGetMonthBugFix - getMonthBugFix) + "개월":(dayMax(date.getFullYear(),date.getMonth()) - Math.abs(nowDate.getDate() - date.getDate())) + "일"
        :(nowDate.getFullYear() - date.getFullYear()) + "년") + " 전";
    }catch{
        return "Err";
    }
}
export const dayMax = (years : number,month : number)=>{
    return (month <= 7)?(month === 2)?(28 + Number(years%4 === 0)):30 + Number(month%2 === 1):30 + Number(month%2 === 0);
}

export const getUnit = (v : string|number) => {
    if (!Number(v)||!v.toString()){return "";};
    const view : number = Number(v);
    const unit = 
    (view < (1000 ^ 4 * 10))?
    (view < (1000 ^ 3))?
    (view < (1000 * 10))?
    (view < 1000)?
    view
    : (view/1000).toFixed(1) + "천"
    : (view/10000).toFixed(1) + "만"
    : (view/100000000).toFixed(1) + "억"
    : "표시 불가";
    return unit.toString();
}

export const getDateUnitNoTime = (input = new Date()) =>{
    try{
        return input.getFullYear() + '년 ' + (input.getMonth()+1) + '월 ' + input.getDate() + '일'
    }catch{
        return "Err";
    }
}
export const getDateUnitWithTime = (input = new Date()) =>{
    
    try{
        return input.getFullYear() + '년 ' + (input.getMonth()+1) + '월 ' + input.getDate() + '일 '
        + input.getHours() + '시 ' + input.getMinutes() + '분 ' + input.getSeconds() + '초 '
    }catch{
        return "Err";
    }
}
export const getDateUnitNoTimeWithBar = (input = new Date()) =>{
    try{
        return input.getFullYear() + '-' + (input.getMonth()+1) + '-' + input.getDate();
    }catch{
        return "Err";
    }
}