import { CustomBtn } from "../anim/custom";
import { CloseBtn } from "icons";

interface tagListInter{
    hashtags : string[]
}
/**
 * @param data 태그 데이터 배열 , {tags : [태그]}
 * @param title 태그 모음의 제목
 * @param className 태그 모음의 클래스명
 * @param max 태그 최대 갯수
 * @param onClick 태그를 클릭했을때 실행할 함수
 * @returns 
 */
export const TagList = ({data,title,className,max = 15,onClick,styles,onDelete} 
    :{data : tagListInter , title? : string ,
    className? : {top? : string , inner? : string , tag? : string}, max? : number,
    styles? : {top? : object , inner? : object , tag? : object},
    onClick? : Function,canDelete? : boolean,onDelete? : Function}) =>{
    return (
        <div className = {"flex-row " + className?.top} style={styles?.top}>
            <div className={"flex-row " + className?.inner} style={styles?.inner}>
                {(data?.hashtags?.length > 0)?
                (data?.hashtags?.map((text,i)=>(i<max)&&(
                <CustomBtn className = {`${className?.tag} row-tag${i} flex-row`}
                key={i} onClick={(e)=>{e.stopPropagation(); if (onClick) onClick(text,i)}}
                style={styles?.tag}
                >
                    <p>#{text}</p>
                    {(onDelete) &&
                    <CustomBtn style={{width : "20px" , height : '20px'}} onClick={()=>{if (onDelete) onDelete(text,i);}}>
                        <CloseBtn width = '10' height = '10'/>
                    </CustomBtn>}
                </CustomBtn>)))
                :(<p style={{color : 'var(--theme-back-color-strong)'}}>(태그가 없습니다)</p>)
                }
            </div>
        </div>
    );
}

export const TagListSkel = () =>{
    return (
        <div className = "list-row-tag-container flex-row skel-draw" style={{height : '24px'}}>
            <p className="list-row-tag-title"></p>
            <div className={`list-row-tag-container flex-row`}>
            </div>
        </div>
    );
}