import { useEffect, useState} from "react";
import interStyle from "./css/interface.module.css";
import { useAtom, useAtomValue } from "jotai";
import { BigMenuObjectAtom, BigMenuTargetAtom, MenuObjectAtom,MenuTargetAtom,SmallMenuObjectAtom,SmallMenuTargetAtom,WindowStateAtom } from "contents/state/state";
import ReactDom from "react-dom";
import cs from "classnames/bind";
import { MenuBackWrapper } from "./wrapper";

const cx = cs.bind(interStyle)

export const SmallMenu = () => {
    const [object,setObject] = useAtom(BigMenuObjectAtom);
    const [smallObj,setSmallObj] = useAtom(MenuObjectAtom);
    const [SmallTarget,setSmallTarget] = useAtom(MenuTargetAtom);
    const [pos,setPos] = useState(null);
    const windowState = useAtomValue(WindowStateAtom);
    const reset = () =>{
        setObject(null);
        setSmallObj(null);
        setSmallTarget(null);
        setPos(null);
    }
    const io = new IntersectionObserver((entries)=>{
        entries.forEach((entry)=>{
            if (entry.isIntersecting){
                setPos(entry.boundingClientRect);
            } else {
                reset();
            }
        })
    });

    useEffect(()=>{
        return ()=>{
            reset();
        }
    },[windowState.mobile]);

    useEffect(()=>{
        if (SmallTarget?.target) io.observe(SmallTarget.target);
        return()=>{
            if (SmallTarget?.target) io.unobserve(SmallTarget.target);
            setPos(null);
        }
    },[SmallTarget]);

    return ReactDom.createPortal(
        (pos)&&(<MenuBackWrapper className={cx({"only-pc" : (object)})} isOpened = {SmallTarget} blackBack={false} cb={()=>reset()} style={{left : '0' , top : '0'}}>
        <div className={cx("ShareContainer",'row-container-shadowed')} 
            onClick={(e)=>e.stopPropagation()}
            style={{visibility : (SmallTarget)?'visible':'hidden',
            left : (pos?.x),
            top : (pos?.y),
            transform : `translate3d(${(windowState.width/2 < pos?.right && windowState.width/2 < pos?.left)?'-100%':'0%'},${(windowState.height/2 < pos?.bottom)?'-100%':`calc(0% + ${pos.height}px)`},0)`
            }}>
                {smallObj||object}
        </div>
        </MenuBackWrapper>),document.querySelector('#portal'));
}