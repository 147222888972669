import { CustomBtn, CustomClose } from "contents/func/anim/custom";
import Cropper from "react-cropper";
import { Fragment, MouseEventHandler, useRef, useState } from "react";
import cn from "classnames/bind";
import editorStyle from "./editor.module.css";
import interfaceStyle from "contents/interface/css/interface.module.css";
import { ImageEditorLinkAtom, ImageEditorOnAtom, ImagePreviewAtom } from "contents/state/state";
import { useAtom, useSetAtom } from "jotai";
import { fileHandler } from "contents/func/network/imageHandler";
import { MenuBackWrapper } from "contents/interface/wrapper";
import ReactDom from "react-dom";

const cx = cn.bind(editorStyle);
const cl = cn.bind(interfaceStyle);

export const ImgEditor = ({cb} : {cb:Function}) =>{
    const editorRef = useRef<any>();
    const [link,setLink] = useAtom(ImageEditorLinkAtom);
    const [vlink,setVLink] = useState<string|null>(null);
    const [page,setPage] = useState('main');

    const onCrop = () => {
        const cropper = editorRef.current?.cropper;
        console.log(cropper);
        const cropped = cropper.getCroppedCanvas()?.toDataURL()
        setVLink(cropped);
        setPage("main");
    }

    const onCropCancel = () =>{
        const cropper = editorRef.current?.cropper;
        console.log(cropper);
        cropper.clear();
        setPage("main");
    }

    const onReset = () =>{
        const cropper = editorRef.current?.cropper;
        cropper.reset();
        setVLink(null);
    }

    const setRatio = (ratio : number) =>{
        const cropper = editorRef.current?.cropper;
        cropper.setAspectRatio(ratio)
    }

    const setCropMode = () =>{
        const cropper = editorRef.current?.cropper;
        cropper.crop();
    }
    
    const cropMoveEnd = () =>{
        const cropper = editorRef.current?.cropper;
        cropper.setCanvasData({
            left : 0,
            top : 0,
            width : Infinity,
        });
    }

    const onSubmit = (e : any) =>{
        const cropper = editorRef.current?.cropper;
        if (cb) cb(vlink);
    }
    
    return (
        <div className={cx('image-editor','flex-column')}>
            <div className={cx("image-form","flex-column")}>
                <Cropper
                    src={vlink||link||""}
                    viewMode={3}
                    background={false}
                    dragMode = "move"
                    autoCrop = {false}
                    autoCropArea = {1}
                    guides={false}
                    ref = {editorRef}
                    onMouseUp={cropMoveEnd}
                />
            </div>
            <div className={cx("navbar-temp")}>
                <div className={cx("bottom-temp")}>
                    <div className={cx("bottom-navbar",'flex-row')}>
                        {
                            {
                                "main":
                                <Fragment>
                                    <CustomBtn className={cx("navbar-buttons","row-container",'flex-row')} onClick={()=>{setCropMode(); setPage('crop');}}>
                                        크롭
                                    </CustomBtn>
                                    <CustomBtn className={cx("navbar-buttons","row-container",'flex-row')} onClick={()=>{onReset(); setPage('main');}}>
                                        초기화
                                    </CustomBtn>
                                    <CustomBtn onClick={onSubmit} className={cx("navbar-buttons","row-container",'flex-row')}>
                                        확인
                                    </CustomBtn>
                                </Fragment>,
                                'crop': 
                                <Fragment>
                                    <CustomBtn onClick={onCropCancel} className={cx("navbar-buttons","row-container",'flex-row')}>
                                        취소
                                    </CustomBtn>
                                    <CustomBtn onClick={onCrop} className={cx("navbar-buttons","row-container",'flex-row')}>
                                        확인
                                    </CustomBtn>
                                </Fragment>
                            }[page]
                        }
                        
                    </div>
                    <div className={cx("bottom-subbar",'flex-row')}>
                        {
                            {
                                'crop' : 
                                <Fragment>
                                    <CustomBtn className={cx("navbar-buttons","row-container",'flex-row')} onClick={()=>{setRatio(1/1);}}>1:1</CustomBtn>
                                    <CustomBtn className={cx("navbar-buttons","row-container",'flex-row')} onClick={()=>{setRatio(4/3);}}>4:3</CustomBtn>
                                    <CustomBtn className={cx("navbar-buttons","row-container",'flex-row')} onClick={()=>{setRatio(16/9);}}>16:9</CustomBtn>
                                </Fragment>,
                            }[page]
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export const ImgEditView = () => {
    const bigMenuRef = useRef<any>();
    const [on,setOn] = useAtom(ImageEditorOnAtom);
    const setImage = useSetAtom(ImagePreviewAtom);
    console.log(on);

    async function base64toFile(base_data : string, filename : string) {
        var arr = base_data.split(','),
            mime = arr[0].match(/:(.*?);/)![1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
    
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }
    const imageEdited = async(link : string) =>{
        if (link) await fileHandler({
        event : {
            target : {
                files : [await base64toFile(link,"temp.png")]
            }
        },
        endPoint : '/serve/file/images',
        column : 'file',
        onSuccess : (res)=>{
            setImage((prev)=>{
                let array = [...prev];
                const arr = [...array,...res]
                return arr;
            });
        }});
    }

    return ReactDom.createPortal(
        <MenuBackWrapper isOpened = {on} blackBack={true}>
            <div className={cl("view-module-fixed")} ref = {bigMenuRef}
            style={{visibility : (on)?'visible':'hidden' , opacity : (on)?'1':'0'}}>
                <div className={cl("view-module-modal-etc","scrolling")} onClick={(e)=>e.stopPropagation()}>
                    <CustomClose onClick={()=>setOn(false)}/>
                    <ImgEditor cb={(link : string)=>{setOn(false); imageEdited(link)}}/>
                </div>
            </div>
        </MenuBackWrapper>
        ,document.querySelector('#portal') as HTMLDivElement
    );
}