import React, { useContext, useState } from "react";
import { cateContext } from "./cate";
import { AddBtn, AddBtnAlt, CertBtn, HeartBtn, HeartFillBtn, RightBtn } from "icons";
import styles from "./category.module.css";
import { CustomBtn, CustomImg, CustomLabel } from "contents/func/anim/custom";
import { useAtomValue, useSetAtom } from "jotai";
import { BigMenuObjectAtom, MenuObjectAtom, MenuTargetAtom } from "contents/state/state";
import cn from "classnames/bind";
import TempImg from 'icons/user/logo.png';

const cx = cn.bind(styles);

export const CateRow = (
    {p,cb,otherCb,alter = '',htmlFor,customIcon,style,childEnabled}
    :{p : any , htmlFor : any, cb? : Function , otherCb? : Function,
        alter? : string , customIcon? : any, style? : object,
        childEnabled? : boolean
    }) =>{
    const setObject = useSetAtom(MenuObjectAtom);
    const setTarget = useSetAtom(MenuTargetAtom);

    return (
        <CustomBtn onClick = {(e)=>{if (otherCb) otherCb(e);}} className={styles.container + " row-container flex-row"} style={style}>
            <div className = {cx('linkBtnContainer')}>
                {(p.children?.length >= 1&&childEnabled)&&
                <CustomLabel className={ styles.linkBtn + " flex-row btn-style-grey-back"} htmlFor = {htmlFor}>
                    <RightBtn width = '16px' height = "16px" style={{minHeight:'auto'}}/>
                </CustomLabel>}
            </div>
            <div className={cx('prof-container-middle')}>
                <CustomImg src="" width = '100%' height = "100%" altLink = {TempImg}/>
            </div>
            {(p)&&<>
                <div className={styles.info}>
                <div className={styles.title + " flex-row"}>{customIcon}{p?.categoryName||alter}</div>
                    <div className={styles.description}>"{p.categoryDescription}"</div>
                </div>
                <CustomBtn onClick={(e)=>{
                    e.stopPropagation();
                }} className = {cx('subscribeBtnContainer',"flex-row")}>
                    구독
                </CustomBtn>
                <div className = {cx('linkBtnContainer')}>
                    <CustomBtn className={ styles.linkBtn + " flex-row btn-style-grey-back"}
                    onClick={(e)=>{e.stopPropagation(); setTarget(e); setObject(<CateSubMenu id={p.categoryId}/>);}}>
                        <p style={{fontWeight:'bold' , fontSize : '100%' , writingMode : "vertical-lr"}}>•••</p>
                    </CustomBtn>
                </div>
            </>}
        </CustomBtn>
    );
}

export const CateAdd = ({onClick , style} : {onClick? : ()=>void , style? : Object}) =>{
    return (
        <CustomBtn onClick = {onClick} className={cx('container','addBtn','flex-row')} style={style}>
            <AddBtnAlt width = '24px' height = "24px"/>
        </CustomBtn>
    );
}

export const CateSubMenu = ({id} : {id : Number}) =>{
    return (
        <div className={cx('smallMenuSub',"flex-column")}>
            <p>이 카테고리</p>
            <CustomBtn className={cx('smallMenuSubMenu','flex-row')}>
                <CertBtn width='12' height='12' fill="var(--theme-red-color)" stroke="var(--theme-red-color)"/>
                <p>신고</p>
            </CustomBtn>
        </div>
    );
}

export const CateRowSkel = () =>{
    return (
        <div className={styles.container + " row-container flex-row"} style={{}}>
            <div className = {cx('linkBtnContainer')}>
                <div className={ styles.linkBtn + " flex-row btn-style-grey-back"}>
                </div>
            </div>
            <div className={cx('prof-container-middle','skel-draw')}></div>
                <div className={styles.info}>
                    <div className={styles.title + " flex-row skel-draw"} style={{height : '22px',marginBottom : '2px'}}></div>
                    <div className={styles.description + ' skel-draw'} style={{height : '18px'}}></div>
                </div>
                <div className = {cx('linkBtnContainer')}>
                    <div className={ styles.linkBtn + " flex-row btn-style-grey-back"}>
                    </div>
                </div>
        </div>
    );
}