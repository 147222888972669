import { decodingToken } from "./decode";

export const TokenCheck = (token : string) => new Promise((resolve,reject)=>{
    const authToken = token;
    const userToken = localStorage.getItem('userToken');
    if (authToken||userToken){
        decodingToken(authToken||userToken)
            .then((res)=>resolve(res.parsed))
            .catch((err)=>reject(err))
    } else {
        reject('토큰을 찾을 수 없습니다');
    }
});