import { ReactNode, useEffect } from "react";
import wrapperStyle from "./css/wrapper.module.css";
import cn from "classnames/bind";

const cx = cn.bind(wrapperStyle);

export const MenuBackWrapper = ({children,cb,isOpened,blackBack = false , className , style} 
    :{children? : ReactNode,cb? : Function , isOpened : boolean , blackBack? : boolean , className? : string , style? : object}) =>{
    
        useEffect(()=>{
            if(isOpened) document.body.style.overflow = 'hidden';
            else document.body.style.overflow = 'auto'

            return()=>{
                
            }
        },[isOpened]);
    
        return (
        <div className={cx(className,"wrapper")}
        onClick={(e)=>{e.stopPropagation(); if (cb) cb()}}
        style={{...style , visibility : (isOpened)?'visible':'hidden' , backgroundColor : (blackBack)?'rgba(0,0,0,0.5)':'none' , opacity : (isOpened)?'1':'0'}}>   
            {children}
        </div>
    );
}

