import {Route, Routes, useLocation} from "react-router-dom";
import Navbar from "./contents/navbar";
import React , {Fragment, lazy , useEffect, useRef} from "react";
import { NavbarLeft } from "./contents/navbar/navbar-left";
import { CurrentUserAtom, ModalAtom, WindowStateAtom, pageSettingAtom } from "./contents/state/state";
import { ContentsView, Modal, TopSearchForm } from "./contents/interface";
import { ContentView } from "./contents/post/view/view";
import ReactDom from "react-dom";
import SendMessageComponent from "./contents/sse/sseSendAlert";
import { MenuBackWrapper } from "./contents/interface/wrapper";
import { MyTagPage } from "./contents/func/tag/tagpage";
import RecomPage from "contents/post/recommend/recom";
import { decodingToken } from "contents/token/decode";
import { useAtom, useSetAtom } from "jotai";
import { ListHeader } from "./contents/post/list";
import UserEdit from "contents/user/info/editinfo";
import { SSEProvider } from "contents/sse/sseSubscribe";
import { ImgEditView } from "contents/func/img/editor/editor";

const ListMap = lazy(async() => await import("./contents/post/list"));
const UserPage = lazy(async() => await import("./contents/user/info/userInfo"));
const WritePage = lazy(async() => await import("./contents/post/write"));
const NotiList = lazy(async() => await import("./contents/notification"));
const MapPage = lazy(async() => await import("./contents/map/mapPage"));
const MainPage = lazy(async() => await import("./contents/main/main"));
const SubCateMap = lazy(async() => await import("./contents/post/cate/cate"));
const TagListPage = lazy(async() => await import("./contents/func/tag/tagpage"));

function Routing() {
  const Location = useLocation();
  const linkRef = useRef();
  const [menuOn,setMenuOn] = useAtom(ModalAtom);
  const [pageSetting,] = useAtom(pageSettingAtom);
  const [windowState,] = useAtom(WindowStateAtom);
  const setUser = useSetAtom(CurrentUserAtom);

  useEffect(()=>{
    if (localStorage.getItem('userToken')){
      decodingToken(localStorage.getItem('userToken'))
      .then((res)=>{console.log(res); setUser(res.parsed)})
      .catch((err)=>{
        console.log(err);
        localStorage.removeItem('userToken');
        window.location.replace(pageSetting.login);
      })
    }
  },[]);

  return (
    <React.Fragment>
      <SSEProvider/>
      <TopSearchForm/>
      {ReactDom.createPortal(
        <Fragment>
          <ContentsView>
              <ContentView/>
          </ContentsView>
          <Modal/>
          <ImgEditView/>
        </Fragment>
      ,document.querySelector('#portal'))}
        <div className="router-container">
            <div className = "router-width-div">
                <aside className = "sub-router-left" 
                style={{visibility : (menuOn)?'visible':'hidden', opacity : (menuOn)?'1':'0',
                width : (menuOn)?(windowState.width>1200)?'45%':(windowState.mobile)?'75%':'72px':'0px'}}>
                  {(windowState.mobile)&&<MenuBackWrapper isOpened={menuOn} cb={()=>setMenuOn(false)} style={{right : 0,height : '100dvh'}}></MenuBackWrapper>}
                  {(menuOn)&&<NavbarLeft/>}
                </aside>
                <div className = "router scrolling" ref={linkRef}>
                  <Routes location={Location} key={Location.pathname}>
                      <Route path='/*' element = {<></>}/>
                      <Route path={pageSetting.main} element = {<MainPage/>}/>
                      <Route path={pageSetting.list + '/*'} element = {<ListMap/>}/>
                      <Route path={pageSetting.write} element={<WritePage/>}/>
                      <Route path={pageSetting.userInfo} element={<UserPage/>}></Route>
                      <Route path={pageSetting.category} element = {<SubCateMap/>}/>
                      <Route path={pageSetting.bookmark} element = {<ListMap/>}/>
                      <Route path={pageSetting.alert} element={<NotiList/>}/>
                      <Route path={pageSetting.map} element={<MapPage/>}/>
                      <Route path={pageSetting.tag + '/*'} element = {<TagListPage/>}/>
                      <Route path={pageSetting.myTag} element={<MyTagPage/>}/>
                      <Route path={pageSetting.userEdit} element={<UserEdit/>}></Route>
                      <Route path="/send-message" element={<SendMessageComponent />} />
                  </Routes>
                </div>
                <div className="subbar flex-column only-pc-flex">
                  <Routes location={Location} key={Location.pathname}>
                        <Route path={pageSetting.list + '/*'} element={<RecomPage/>}></Route>
                        <Route path={pageSetting.tag + '/*'} element={<ListHeader/>}></Route>
                  </Routes>
                </div>
            </div>
        </div>
      <Navbar/>
    </React.Fragment>
  );
}

/*
                
*/

export default Routing;