import { BigMenuObjectAtom, MenuObjectAtom, MenuTargetAtom } from "contents/state/state";
import { useSetAtom } from "jotai";
import { useSetAlert } from "../toast/toast";

const useShare = () => {
    const setObject = useSetAtom(BigMenuObjectAtom);
    const setTarget = useSetAtom(MenuTargetAtom);
    const Alert = useSetAlert();

    async function copyUrl(targetUrl : string){
        if (navigator.clipboard){
            await navigator.clipboard?.writeText(targetUrl).then(()=>{
                Alert("클립보드에 복사되었어요");
            }).catch((err)=>{
                Alert("클립보드 복사에 실패했어요");
                console.log(err);
            });
        } else {
            Alert("다른 곳에서 시도해주세요")
        }
        setObject(null);
        setTarget(null);
    } 

    return {copyUrl};
}

export default useShare;