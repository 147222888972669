import AlertStyle from "./css/toast.module.css";
import cn from "classnames/bind";
import React, { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { last, take } from "lodash";
import { AnimatePresence, m, motion } from "framer-motion";
import { uniqueId } from "lodash";

const cx = cn.bind(AlertStyle);
export const alertContext = createContext<any>(undefined);

export const AlertList = ({children} : {children : ReactNode}) =>{
    const [AlertList,setAlertList] = useState<any>([]);
    const max = 5;
    const ref = useRef<any>();
    useEffect(()=>{
        if (AlertList.length > max){
            setAlertList((p : any)=>{
                return take(p,max)
            })
        }
    },[AlertList]);

    return (
        <alertContext.Provider value={{AlertList,setAlertList}}>
            {children}
            {createPortal(
                <div className={cx('flex-column','alertContainer')} ref={ref}>
                    {AlertList?.slice(-1 * max)?.map((str : {string : string , date : Date},i : number)=>(
                        <AlertRow str = {str.string} key={str.date.toString()}/>
                    ))}
                </div>
            ,document.querySelector("#alert") as HTMLElement)}
        </alertContext.Provider>
    )
}
//animation : 0.5s ease-in 2s 1 forwards running alert;
/*

*/
export const useSetAlert = () =>{
    const {setAlertList} = useContext(alertContext);

    const Alert = (str : any) =>{
        setAlertList((p : any)=>{return take([{string : str , date : uniqueId(str)},...p],5)});
    }
    
    return Alert;
}


export const AlertRow = ({str,style} : {str : String|ReactNode , style? : Object}) =>{
    const [visible,setVisible] = useState(true);
    const {setAlertList} = useContext(alertContext);

    useEffect(()=>{
        const set = setTimeout(()=>{
            setVisible(false);
        },3500);
        return ()=>clearTimeout(set);
    },[]);

    return(
        <AnimatePresence>
            {(visible)&&
            <motion.div
            style={style}
            initial={{
                y : '-100%',
                opacity : 0
            }}
            animate ={{
                y : '0',
                opacity : 1
            }}
            exit = {{
                opacity : 0,
            }}
            transition={{
                type: "spring", duration : 0.2
            }}
            className={cx('alertRow','row-container-shadowed','flex-column')}>
                {str}
            </motion.div>}
        </AnimatePresence>
    )
}