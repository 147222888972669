import React from 'react';
import { Helmet } from 'react-helmet-async';

const DynamicMeta = ({title,description,image,size,url} : {title : string , description : string , image? : string , size? : {width : string , height : string} , url : string}) => (
    <Helmet>
      <title>{title + ' - 커리어블록'}</title>
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <link rel="icon" href={image||'/logo.png'} type="image/x-icon"/>
      <meta property="og:url" content={'//www.career-block.com/' + url} />
      <meta name="og:title" content={title + ' - 커리어블록'} />
      <meta name="og:description" content={description} />
      <meta property="og:image" content={image||'/logo.png'} />
      <meta property="og:image:width" content={size?.width||'540'} />
      <meta property="og:image:height" content={size?.height||'540'} />
      <meta name="twitter:card" content="커리어블록" />
      <meta name="twitter:title" content={title + ' - 커리어블록'} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image||'/logo.png'} />
    </Helmet>
);

export default DynamicMeta;