import {Route, Routes, useLocation} from "react-router-dom";
import React , {useEffect, lazy , Suspense} from "react";
import nProgress from "nprogress";
import { ErrorBoundary } from "react-error-boundary";
import errorImg from "./errorImg.png";

import "nprogress/nprogress.css"
import "./css/App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "react-quill/dist/quill.snow.css";
import "./css/list/row.css";
import "./css/post/write.css";
import "react-quill/dist/quill.snow.css";
import 'cropperjs/dist/cropper.css';

import { WindowStateAtom, pageSettingAtom } from "contents/state/state";
import { GoogleCallback, KakaoCallback, NaverCallback } from "contents/user/auth";
import Routing from "./routing";
import { useAxiosIntercepter } from "contents/func/network/axios";
// SSE provider 
import { LogViewer } from 'contents/sse/logViewer';
import { useAtom, useAtomValue } from "jotai";
import { BigMenu, ImgPreview } from "contents/interface/mobile";
import { SmallMenu } from "contents/interface/pc";
import { CustomBtn } from "contents/func/anim/custom";
import { LoadingBar } from "contents/func/anim/loading";
const Login = lazy(async() => await import("contents/user/login"));
const JoinPage = lazy(async() => await import("contents/user/join"));
const FindUserInfo = lazy(async()=>await import("contents/user/info/findinfo"));

function App() {
  useAxiosIntercepter();
  const pageSetting = useAtomValue(pageSettingAtom);
  const Location = useLocation();
  const [windowState,setWindowState] = useAtom(WindowStateAtom);
  const screenResize = () =>{
    if (windowState?.width !== window.innerWidth) setWindowState((prev)=>{return {...prev , width : window.innerWidth}});
    if (windowState?.height !== window.innerHeight) setWindowState((prev)=>{return {...prev , height : window.innerHeight}});
    setWindowState((prev)=>{return {...prev , mobile : (window.innerWidth < 768)}});
  }

  useEffect(()=>{
    screenResize();
    window.addEventListener('resize',screenResize);
    return ()=>{
      window.removeEventListener('resize',screenResize);
    }
  },[window.innerWidth]);

  return (
    <div className="App">
      <BigMenu/>
      <SmallMenu/>
      <ImgPreview/>
      <ErrorBoundary fallbackRender={fallbackRender} onReset={(_)=>window.location.reload()}>
        <Suspense fallback={<Loading/>}>
          <Routes location={Location} key={Location.pathname}>
            <Route path="/*" element={<Routing/>}/>
            <Route path={pageSetting.login} element = {<Login/>}/>
            <Route path={pageSetting.join} element = {<JoinPage/>}/>
            <Route path={pageSetting.findUser} element={<FindUserInfo/>}/>
            <Route path = {pageSetting.naverAuth} element = {<NaverCallback/>}/>
            <Route path = {pageSetting.googleAuth} element = {<GoogleCallback/>}/>
            <Route path = {pageSetting.kakaoAuth} element = {<KakaoCallback/>}/>
            <Route path = "/logViewer" element = {<LogViewer/>}/>
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

function fallbackRender({error , resetErrorBoundary}:{error : Error , resetErrorBoundary : any}){
  return (
      <div role="alert" className="error-container flex-column">
      <div>
        <img width = "100%" src={errorImg}></img>
        <p className="error-title">현재 사이트에 접속 할 수 없어요</p>
        <p className="error-content">{error.message}</p>
      </div>
      <CustomBtn className="error-retry-btn row-container" onClick={()=>resetErrorBoundary()}>재시도</CustomBtn>
    </div>
  );
}
/*                        <Route path={pageSetting.view} element = {<ContentsView/>}/>*/
const Loading = () => {
  useEffect(() => {
    nProgress.start();
    return () => {
      nProgress.done();
    };
  }, []);

  return(
    <LoadingBar style={{width : '72px' , left : '50%' , top : '50%' , transform : 'translate(-50%,-50%)'}}/>
  );
};

export default App;