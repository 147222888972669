import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { BigMenuObjectAtom, ImageEditorLinkAtom, ImageEditorOnAtom, ImagePreviewAtom, ImageStateAtom, MenuObjectAtom, MenuTargetAtom, pageSettingAtom, TagStateAtom, WriteAtom} from "contents/state/state";
import { Motion } from "contents/func/anim/motions";
import ReactQuill from "react-quill";
import { fileHandler } from "contents/func/network/imageHandler";
import { CloseBtn, DeleteBtn, EditBtn, PictureBtn, VideoBtn} from "icons";
import { TagList } from "contents/func/tag/taglist";
import { AxiosIns } from "contents/func/network/axios";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import DatePicker from "contents/func/unit/datepicker";
import { debounce } from 'lodash';
import { getDateUnitNoTime } from "contents/func/unit";
import { useQueryClient } from "react-query";
import TagStyles from "contents/func/tag/tags.module.css";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useSetAlert } from "contents/func/toast/toast";
import { CustomBtn, CustomLabel } from "contents/func/anim/custom";
import writeStyle from "./write.module.css";
import cn from "classnames/bind";
import { MapSelection } from "contents/map/map";
import { ImageSlider } from "contents/func/img/slick/slider";
import { ImgEditView } from "contents/func/img/editor/editor";

const cx = cn.bind(writeStyle);
const writeContext = createContext<any>(undefined);

const WritePage = ({mode = "write",page}
    :{mode? : "write"|"edit",page? : number}) =>{
    const [data,setData] = useAtom(WriteAtom);
    const [image,setImage] = useAtom(ImageStateAtom);
    const [tags,setTag] = useAtom(TagStateAtom);
    const [searchParam,setSearchParam] = useSearchParams();
    const category = searchParam.get('category');
    /*const location = useLocation();
    const [mode,setMode] = useState(location.state?.mode);
    const [page,setPage] = useState(location.state?.page);*/
    const Alert = useSetAlert();
    const queryClient = useQueryClient();
    const pageSetting = useAtomValue(pageSettingAtom);
    const navigate = useNavigate();
    const SetInput = (key : string,value : any) =>{
        setData((prev: any)=>{return {...prev,[key] : value}});
    }
    const SetPage = (data : string,page : number,Max : number = 2,lengthMax : number = 2)=>{
        if (data&&data.length >= Max) SetInput('page',page+1); else Alert(lengthMax + '자 이상 적어주세요');
    }
    const ResetInput = () =>{
        setData({
            page : 1,
            title : '',
            caption : "",
            isPrivate : false,
            status : "Published",
            postHashTags : [],
            imagesLinks : [],
            postCategory : 0,
            create_at : new Date(),
            content : '',
            position : {
                name : "",
                lat : 35,
                lng : 129
            }
        });
        setImage([]);
        setTag([]);
    }
    const SetBackPage = () =>{
        SetInput('page',data.page-1);
    }
    const WritePost = async() => {
        switch(mode){
            case 'write' : 
                await AxiosIns.post('/api/restrict/user/write',{
                    title : data.title ,
                    caption : 'TEMP' ,
                    content : data.content ,
                    isPrivate : data.isPrivate ,
                    status : data.status,
                    postHashtags : tags,
                    imageLinks : image ,
                    postCategory : category
                }).then(()=>{
                    queryClient.invalidateQueries(['Get-Lists-Key-Cate' + category]);
                    navigate(-1);
                    ResetInput();
                }).catch(()=>Alert('피드 게시중 오류가 발생했습니다'));
            break;
            case 'edit' :
                await AxiosIns.patch(`/api/restrict/user/alterPost/${page}`,{
                    title : data.title ,
                    content : data.content ,
                    isPrivate : data.isPrivate ,
                    status : data.status,
                    hashtags : tags,
                    imageLinks : image
                }).then(()=>{
                    queryClient.invalidateQueries(['Get-View-Keys',page]);
                    Alert('수정이 완료되었어요')
                    navigate(-1);
                    ResetInput();
                }).catch(()=>Alert('피드 수정중 오류가 발생했습니다'));
            break;
        }
    };

    useEffect(()=>{
        if (mode){
            if (page){
                AxiosIns.get(`/api/getPostByPostId/${page}`)
                .then(({data})=>{
                    setData({
                        page : 1,
                        title : data?.title,
                        content : data?.content,
                        isPrivate : data?.private,
                        create_at : new Date(),
                        caption : "",
                        status : "Published",
                        postHashTags : [],
                        imagesLinks : [],
                        postCategory : 0,
                        position : {
                            name : "",
                            lat : 35,
                            lng : 129
                        }
                    });
                    setTag(data?.hashtags);
                    setImage(data?.imageLinks);
                }).catch((err)=>{
                    console.log(err);
                    Alert("게시글을 불러오는데 실패했어요");
                });
            }
        }
        else navigate(pageSetting.main);

        return () =>{
            ResetInput();
        };
    },[]);

    return (
        <div className="write-page flex-column">
            <writeContext.Provider value={{SetPage,SetInput,SetBackPage,WritePost}}>
                {
                    {
                        1 : <ActivityPage/>,
                        2 : <ActivityDatePage/>,
                    }[data.page]
                }
            </writeContext.Provider>
        </div>
    );
}

const ActivityPage = () =>{
    const {SetPage,SetInput} = useContext(writeContext);
    const [data,] = useAtom(WriteAtom);
    const navigate = useNavigate();
    const quillRef = useRef(null);

    const modules= useMemo(()=>{return{
        toolbar:[
            //[{ 'font': [] }],
            ['bold', 'italic', 'underline','strike', 'blockquote',
            {'list': 'ordered'}, {'list': 'bullet'},
            { 'align': [] }, { 'color': [] }, { 'background': [] }],
        ],
        history: {
            delay: 500,
            maxStack: 100,
            userOnly: true,
        },
    }},[]);
    
    const formats = [
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'size',
        'align', 'color', 'background','width','height',
        'video'
    ];

    return (
        <Motion className="write-page-content activity-page">
            <div className="write-page-activity-form flex-column">
                <p>어떤 활동을 기록할까요?</p>
                <div className={cx("flex-row",'activity-row')}>
                    <div className={cx("flex-column",'image-part')}>
                        <ImageSlider/>
                    </div>
                    <div className={cx("flex-column")}>
                        <input className="write-page-activity-input" type='text' required onChange = {(e)=>{SetInput('title',e.target.value)}} placeholder="피드의 제목을 적어주세요 (2자 이상)" onKeyDown={(e)=>{if (e.key === "Enter") SetPage(data.title,data.page)}} value = {data.title}></input>
                        <ReactQuill
                        className = "Write-Quill scrolling flex-column"
                        formats = {formats}
                        ref = {quillRef}
                        placeholder = "피드의 내용을 적어주세요"
                        value={data.content}
                        theme = "snow"
                        modules = {modules}
                        onChange = {(e)=>{SetInput('content',e);}}
                        ></ReactQuill>
                    </div>
                </div>
                <div className={cx("write-page-bottom-container","flex-row")}>
                    <CustomBtn className={cx("write-page-submit","flex-row","row-container")} onClick={()=>{SetPage(data.title,data.page);}}>다음</CustomBtn>
                </div>
            </div>
        </Motion>
    );
}

/*
    const settings = {
        ...globalSettings,
        beforeChange: handleBeforeChange,
        afterChange: handleAfterChange,
        prevArrow : <LeftArrow/>,
        nextArrow : <RightArrow/>,
    };
    const onClickCard = (e,arr,i) => {
        if (drag){e.preventDefault(); e.stopPropagation();}
        else {setObject(<ActivityImageEditMenu link={arr} index={i}/>); setTarget(e);}
        return;
    }
    const customPaging = (i) =>{
        return (
            <CustomBtn className={cx('slick-btn-container','flex-row')}>
                {
                    (Image[i]?.includes(process.env.REACT_APP_VIDEO_PREFIX||""))?
                    <VideoBtn width="100%" height="100%"/>
                    :
                    <PictureBtn width="100%" height="100%"/>
                }
            </CustomBtn>
        );
    }

<Slider {...settings} customPaging={customPaging}>
    {Image.map((arr,i)=>(
        (arr.includes(process.env.REACT_APP_VIDEO_PREFIX))?
        <HlsReading src={arr}/>
        :
        <CustomImg className={"write-page-added-images image" + i} src={arr} alt=""
        onClick={(e)=>{onClickCard(e,arr,i)}}/>
    ))}
    <div className={"write-page-added-images flex-column"}>
        <CustomBtn htmlFor="" className={cx("add-btn","flex-column")} onClick={(e)=>{
            setObject(<ActivityImageMenu/>); setTarget(e);
        }}>
            <AddBtnAlt width = '50%' height = '50%'/>
        </CustomBtn>
    </div>
</Slider>
*/
const ActivityDatePage = ({mode = "write"} : {mode? : "write"|"edit"}) =>{
    const {SetInput,SetBackPage,WritePost} = useContext(writeContext);
    const [pickedDate,setPickedDate] = useState(new Date());
    const setObject = useSetAtom(BigMenuObjectAtom);
    const setTarget = useSetAtom(MenuTargetAtom);
    const [data,setData] = useAtom(WriteAtom);
    const location = useLocation();
    //const [mode,setMode] = useState(location.state?.mode);

    return (
        <Motion className="write-page-content activity-page">
            <div className="write-page-activity-form flex-column">
                <p>그 외에 추가하고 싶은 내용 있을까요?</p>
                <div className={cx("write-page-activity-content","flex-row")}>
                    <div className={cx("activity-date-container",'flex-column',"row-container")}>
                        <p>시간</p>
                        <CustomBtn className="write-page-date-btn flex-row" onClick={(e)=>{
                        setTarget(e); 
                        setObject(
                            <DatePicker size={{height : '100%' , width : '100%'}}
                            format={{year : '년' , month : '월' , date : '일'}}
                            defaultDate={data.create_at}
                            cb={(sel)=>{
                                setTarget(null);
                                SetInput('create_at',sel);
                                setPickedDate(sel);
                                }}
                            />
                        )}}>
                            {getDateUnitNoTime(pickedDate)}
                            <EditBtn width = "16" height="16"></EditBtn>
                        </CustomBtn>
                        <p>장소</p>
                        <CustomBtn className="write-page-date-btn flex-row" onClick={(e)=>{
                            setTarget(e);
                            setObject(<MapSelection
                                initPos={{lat : data?.position?.lat , lng : data?.position?.lng}}
                                cb={(name,lat,lng)=>{
                                setData((p: any)=>{return {...p,position : {name : name, lat : lat , lng : lng}}});
                                }}
                            />);
                        }}>
                            {data.position?data.position.name?"위치 : " + data.position.name:"위치 : 선택하신 위치":"위치를 선택해주세요"}
                            <EditBtn width = "16" height="16"></EditBtn>
                        </CustomBtn>
                    </div>
                    <div className={cx("activity-tag-container",'flex-column',"row-container")}>
                        <ActivityTagPage/>
                    </div>
                    <div className={cx("activity-setting-container",'flex-column',"row-container")}>
                        <div className="write-page-private flex-row" style={{width : '100%'}}>
                            <p>나만 볼 수 있는 피드로 할까요?</p>
                            <input type="checkbox" id="toggle" checked = {data.isPrivate} onChange={e=>{SetInput('isPrivate',e.target.checked)}} hidden></input> 
                            <label htmlFor="toggle" className="toggleSwitch">{(data.isPrivate)?'네':'아니오'}</label>
                        </div>
                    </div>
                </div>
                <div className={cx("write-page-bottom-container","flex-row")}>
                    <CustomBtn className={cx("write-page-back","row-container")} onClick={SetBackPage}>이전</CustomBtn>
                    <CustomBtn className={cx("write-page-submit","row-container")} onClick={WritePost}>{(mode == 'write')?'작성':'수정'}</CustomBtn>
                </div>
            </div>
        </Motion>
    );
}
export const ActivityImageMenu = () =>{
    const setImage = useSetAtom(ImageStateAtom);
    const setPreviewImg = useSetAtom(ImagePreviewAtom);
    const setTarget = useSetAtom(MenuTargetAtom);
    const setObject = useSetAtom(MenuObjectAtom);
    const reset = () =>{
        setObject(null); setTarget(null);
    }

    return (
        <div className={cx('menuSub',"flex-column")}>
            <p>추가</p>
            <input type = "file" id = "write-video-input" multiple accept="video/*" onChange={e=>{
                fileHandler({
                    event : e,
                    endPoint : '/serve/file/images',
                    column : 'file',
                    onSuccess : (res)=>{setPreviewImg((prev)=>[...prev,...res]); reset()}
                });
                }} hidden/>
            <input type = "file" id = "write-image-input" multiple accept="image/*" onChange={e=>{
                fileHandler({
                    event : e,
                    endPoint : '/serve/file/images',
                    column : 'file',
                    onMutate : (res)=>setImage((prev)=>[...prev,...res]),
                    onSuccess : (res)=>{setPreviewImg((prev)=>[...prev,...res]); reset()}
            });}} hidden/>
            <CustomLabel className={cx('menuSubMenu','flex-row')} htmlFor="write-image-input"><PictureBtn width='24' height='20' fill="inherit"/><p>사진</p></CustomLabel>
            <CustomLabel className={cx('menuSubMenu','flex-row')} htmlFor="write-video-input"><VideoBtn width='24' height='20' stroke="var(--theme-black-color)" fill="inherit"/><p>동영상</p></CustomLabel>
        </div>
    );
}
export const ActivityImageEditMenu = ({link,index} : {link : string , index : number}) =>{
    const setImage = useSetAtom(ImagePreviewAtom);
    const [vlink,setVLink] = useAtom(ImageEditorLinkAtom);
    const setEditor = useSetAtom(ImageEditorOnAtom);
    const setTarget = useSetAtom(MenuTargetAtom);
    
    const ImageDelete = () =>{
        setImage((prev)=>prev.filter((_e,i)=>i !== index));
        setTarget(null);
    }

    return (
        <div className={cx('menuSub',"flex-column")}>
            <CustomBtn className={cx('menuSubMenu','flex-row')}
            onClick={()=>{setTarget(null); setVLink(link); setEditor(true);}}>
            <EditBtn width='24' height='24' fill="inherit"/>
                <p>편집</p>
            </CustomBtn>
            <CustomBtn className={cx('menuSubMenu','flex-row')} onClick={ImageDelete}
            style={{color : 'var(--theme-red-color)',fill:'var(--theme-red-color)'}}>
                <DeleteBtn width='24' height='24' fill="inherit"/>
                <p>삭제</p>
            </CustomBtn>
        </div>
    );
}

const ActivityTagPage = () =>{
    const [tags,setTags] = useAtom(TagStateAtom);
    const tagRef = useRef<any>();
    const formRef = useRef<any>();
    const [index,setIndex] = useState(0);
    const [tagRes,setTagRes] = useState([]);
    const maxTags = 15;
    const [searchMenuOn,setSearchMenuOn] = useState(false);

    const TagSearch = useMemo(()=>debounce(async(tagName)=>{
        if (tagName) await AxiosIns.get('/api/search/tagByName/' + tagName)
        .then((res)=>setTagRes(res.data||[]))
        .catch((err)=>console.log(err))
    },500),[]);
    /**
     * 태그 입력 함수
     */
    const GetTags = useCallback(async(i : number = tags.length,value : string)=>{
        if (i < maxTags)
        await new Promise<void>((res)=>{setTags(fieldChange({arr : tags,index : i,value : value})); res();})
        .then(()=>{if (tagRef.current){tagRef.current.value = ""; tagRef.current.focus();} setIndex(tags.length + 1); setTagRes([]);});
    },[tags])
    //setTags
    /**
     * 배열의 필드 수정
     */
    const fieldChange = ({arr,index,value}:{arr : Array<string>,index : number,value : string|null})=>{
        let newArr = [...arr];
        newArr[index] = value!;
        
        return newArr.filter(e=>e);
    }

    /**
     * tag 컴포넌트의 콜백함수(태그 수정)
     */
    const tagEdit = (_ : null,i : number) =>{
        if (tagRef.current){tagRef.current.value = tags[i];}
        setIndex(i);
    }
    const TagRefFocus = (e : Event) => {
        if (formRef.current){
            if (formRef.current?.contains(e.target)){
                setSearchMenuOn(true);
            }
            else {
                setSearchMenuOn(false);
            }
        }
    }

    useEffect(()=>{
        document.addEventListener('click',TagRefFocus)
        return()=>{
            document.removeEventListener('click',TagRefFocus)
        }
    },[]);

    return (
        <Motion className="write-page-content activity-page flex-column">
            <div className="write-page-tag-list">
                <TagList data = {{hashtags : tags}} max={maxTags}
                title="" onClick={tagEdit} canDelete={true} onDelete={(_ : any,i : number)=>{
                    setTags(fieldChange({arr : tags,index : i,value : null}));
                    if (tagRef.current){tagRef.current.value = ""; tagRef.current.focus();}
                    setIndex(tags.length + 1);
                    setTagRes([]);
                }}
                className={{top : TagStyles.columnTagContainer,inner : TagStyles.columnTagContainer , tag : TagStyles.Tags}}
                />
            </div>
            <div className="write-page-tag-form" ref={formRef}>
                <div className = "write-page-activity-input page-tag-input">
                    <input
                    type = 'text'
                    required
                    maxLength = {50}
                    ref = {tagRef}
                    placeholder="피드의 태그를 적고 엔터나 스페이스바를 눌러주세요"
                    onChange = {(e)=>(TagSearch(e.target.value))}
                    onFocus={()=>setSearchMenuOn(true)}
                    onKeyPress={(e)=>{if (e.key === "Enter" || e.key === "space") {
                        const target : HTMLInputElement = e.target as HTMLInputElement;
                        const filtered = target.value.replace('#','');
                        GetTags(index,filtered)
                    }}}></input>
                    <CustomBtn className="closeBtn tag-result-close flex-column"
                    onClick = {()=>{if (tagRef.current)tagRef.current.value = ""}}
                    ><CloseBtn height = '50%' fill="white"/></CustomBtn>
                </div>
                {(searchMenuOn)&&
                <div className="write-page-tag-search-result row-container scrolling">
                    <div className = "tag-search-result-part">
                        {tagRes?.map((arr : any,i)=>(
                        <React.Fragment key={i}>
                            <CustomBtn onClick={()=>{GetTags(index,arr.tag)}} className={"flex-row tag-search-result result" + i} key={i}>
                                #{arr.tag}
                            </CustomBtn>
                        </React.Fragment>
                        ))}
                    </div>
                </div>}
            </div>
        </Motion>
    );
}

export default WritePage;