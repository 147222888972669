import { LeftBtn, PictureBtn, RightBtn, VideoBtn } from "icons";
import React, { MouseEventHandler, ReactEventHandler, ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import Slider from "react-slick";
import slickStyle from "./css/slick.module.css";
import { CustomBtn, CustomImg } from "../../anim/custom";
import TempImg from 'icons/user/logo.png';
import cn from "classnames/bind";
import { HlsReading, HlsView } from "contents/video/hls";
import rowStyle from "contents/post/list/css/row.module.css";

const cx = cn.bind(slickStyle);
const cs = cn.bind(rowStyle)

export const globalSettings = {
    dots : true,
    infinite : false,
    speed : 500,
    slidesToShow : 1,
    slidesToScroll : 1,
    responsive: [
        {
            breakpoint : 768,
            settings : {
                arrows: false,
            },
        }
    ]
};
export const LeftArrow = ({ className, style ,onClick } : {className? : string , style? : object , onClick? : MouseEventHandler<HTMLElement>})=>{
    return (
        <CustomBtn onClick={onClick} style={{left : '8px' , zIndex : '5',...style}} className={className + ' flex-column row-container'}>
            <LeftBtn width="100%" height="100%"/>
        </CustomBtn>
    );
}
export const RightArrow = ({ className, style ,onClick } : {className? : string , style? : object , onClick? : ReactEventHandler})=>{
    return (
        <CustomBtn onClick={onClick} style={{right : '8px' , zIndex : '5' , ...style}} className={className + ' flex-column row-container'}>
            <RightBtn width="100%" height="100%"/>
        </CustomBtn>
    );
}

export const SlickComp = ({images , cb , custom , className = ''} : {images : Array<string> , cb? : Function , custom? : object , className? : string}) => {
    const [drag,setDrag] = useState(false);
    const handleBeforeChange = ()=>setDrag(true)
    const handleAfterChange = ()=>setDrag(false)

    const customPaging = (i : number) =>{
        return (
            <CustomBtn className={cx('slick-btn-container','flex-row')}>
                {
                    (images[i]?.includes(process.env.REACT_APP_VIDEO_PREFIX||""))?
                    <VideoBtn width="100%" height="100%"/>
                    :
                    <PictureBtn width="100%" height="100%"/>
                }
                
            </CustomBtn>
        );
    }

    const settings = {
        ...globalSettings,
        ...custom,
        beforeChange: handleBeforeChange,
        afterChange: handleAfterChange,
        prevArrow : <LeftArrow/>,
        nextArrow : <RightArrow/>,
    }
    const onClickCard = (e : any,i : number) => {
        if (drag){e.preventDefault(); e.stopPropagation();}
        else if (cb) cb(e,i);
        return;
    }

    return (
        (images?.length > 0)&&
        <div className={className}>
            <Slider {...settings} customPaging={customPaging} className={cs("list-row-image-slider")}>
                {(images?.map((img : string,i : number)=>(
                    (img?.includes(process.env.REACT_APP_VIDEO_PREFIX||""))?
                    <div className={cs("list-row-images")} key={img} onClick = {(e)=>onClickCard(e,i)}>
                        <HlsReading src={img} autoPlay={true} key={img}/>
                    </div>
                    :
                    <div className={cs("list-row-images")} key={img} onClick = {(e)=>onClickCard(e,i)}>
                        <CustomImg alt="" width="100%" height="100%" src = {img} altLink={TempImg} />
                    </div>)
                ))}
            </Slider>
        </div>||<></>
    );
}

export const SmallSlick = ({Comps = []} : {Comps : Array<ReactNode>}) =>{
    
    return(
    <div className={slickStyle.smallSlick + " flex-row scrolling"}>
        {Comps.map((arr,i)=>(
            <div key = {i} className={slickStyle.smallSlickItems}>
                {arr}
            </div>
        ))}
    </div>
    );
}