import { BigMenuObjectAtom, ImagePreviewAtom, ImageStateAtom, MenuObjectAtom, MenuTargetAtom } from "contents/state/state";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import cn from "classnames/bind";
import ColStyle from "contents/post/list/css/colRow.module.css";
import slickStyle from "./css/slick.module.css";
import { CustomBtn, CustomImg, CustomLabel } from "contents/func/anim/custom";
import { HlsReading } from "contents/video/hls";
import { AddBtnAlt, PictureBtn, VideoBtn, Youtube } from "icons";
import { ActivityImageEditMenu, ActivityImageMenu } from "contents/post/write";

const cs = cn.bind(ColStyle);
const cx = cn.bind(slickStyle);

export const ImageSlider = () =>{
    const Image = useAtomValue(ImagePreviewAtom);
    const ImageStateList = useAtomValue(ImageStateAtom);
    const ImageList = (Image.length>0)?Image:ImageStateList;
    const setObject = useSetAtom(MenuObjectAtom);
    const setTarget = useSetAtom(MenuTargetAtom);

    return (
        <div className={cx('slider-img-container','flex-row')}>
            <div className={cx("slider-img-images" , "addButton","flex-row")}>
                <CustomBtn className={cx("add-btn","flex-column")} onClick={(e)=>{
                    setObject(<ActivityImageMenu/>); setTarget(e);
                }}>
                    <AddBtnAlt width = '50%' height = '50%'/>
                </CustomBtn>
            </div>
            {ImageList.map((arr : string,i : number)=>(
                (arr.includes(process.env.REACT_APP_VIDEO_PREFIX!))?
                <HlsReading src={arr} autoPlay={false}/>
                :
                <CustomBtn className={cx("slider-img-images" , "image" + i)}
                key={arr} onClick={(e)=>{
                    setObject(<ActivityImageEditMenu link={arr} index={i}/>); setTarget(e);
                }}>
                    <img className={cx("slider-img-image" , "image" + i)}
                    src={arr} alt=""/>
                </CustomBtn>
            ))}
            
        </div>
        
    )
}