import navbarStyle from "./css/navbar.module.css";
import cn from "classnames/bind";
import { BackNavBtn, DrawerNavBtn, EtcNavBtn, NavbarTitle, SearchNavBtn, SettingNavBtn } from "./navbar-btn";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { MenuTargetAtom, pageSettingAtom, MenuObjectAtom} from "contents/state/state";
import { CustomLink } from "contents/func/anim/custom";
import { RightBtn} from "icons";
import { UserEtc } from "contents/user/info/userInfo";

const cx = cn.bind(navbarStyle);

export const RouteNavbar = () => {
    const pageSetting = useAtomValue(pageSettingAtom);

    return (
        <Routes>
            <Route path="*" element={<DefaultRoute/>}/>
            <Route path={pageSetting.main} element={<MainRoute/>}/>
            <Route path={pageSetting.messenger} element={<MsgRoute/>}/>
            <Route path={pageSetting.category} element={<FeedRoute title={'카테고리'}/>}/>
            <Route path={pageSetting.myTag} element={<FeedRoute title={'태그'}/>}/>
            <Route path={pageSetting.map} element={<FeedRoute title={'내 위치'}/>}/>
            <Route path={pageSetting.alert} element={<AlertRoute/>}/>
            <Route path={pageSetting.userInfo} element={<UserRoute/>}/>
            <Route path={pageSetting.list + "/*"} element={<FeedListRoute/>}/>
        </Routes>
    );
}

export const MobileNavbar = 
    ({route}
    : {route : {trailing? : ReactNode,title? : ReactNode,leading? : Array<ReactNode>}}
    ) => {
    const {trailing,title,leading} = route;
    return (
        <div className = {cx("navbar-temp",'only-mobile')} style={{top : '0'}}>
            <div className = {cx("navbar-top","flex-row")}>
                <div className = {cx("navbar-top-left","flex-row")}>
                    {trailing}
                </div>
                <div className = {cx("navbar-top-middle","flex-row")}>
                    {title!}
                </div>
                <div className = {cx("navbar-top-right","flex-row")}>
                    {leading?.map((arr)=>(arr))}
                </div>
            </div>
        </div>
    );
}

const DefaultRoute = () =>{
    const route = {
        trailing : 
        <BackNavBtn/>
        ,
        leading : [
            <SearchNavBtn key={"SearchBtn"}/>,
        ]
    }
    return(<MobileNavbar route={route}/>);
}

const MainRoute = () =>{
    const route = {
        trailing : <NavbarTitle title="메인"/>,
        leading : [
            <SearchNavBtn key={"SearchBtn"}/>,
        ]
    }
    return(<MobileNavbar route={route}/>);
}

const MsgRoute = () =>{
    const route = {
        trailing : <NavbarTitle title="메신저"/>,
        leading : [
            <SearchNavBtn key={"SearchBtn"}/>,
            <DrawerNavBtn/>
        ]
    }
    return(<MobileNavbar route={route}/>);
}

const FeedRoute = ({title} : {title : string}) =>{
    const route = {
        trailing : 
        <ListMenuView obj={<FeedNavMenus/>}>
            <NavbarTitle title={title}/>
        </ListMenuView>,
        leading : [
            <SearchNavBtn key={"SearchBtn"}/>,
        ]
    }
    return(<MobileNavbar route={route}/>);
}

const AlertRoute = () =>{
    const route = {
        trailing : <NavbarTitle title="알림"/>,
        leading : [
            <SearchNavBtn key={"AlertBtn"}/>,
        ]
    }
    return(<MobileNavbar route={route}/>);
}

const UserRoute = () =>{
    const [searchParam,] = useSearchParams();
    const route = 
    (searchParam.get('user'))?
    {
        trailing : 
        <BackNavBtn/>,
        leading : [
            <EtcNavBtn object={<UserEtc/>} key={"EtcBtn"}/>,
        ]
    }
    :{
        trailing : 
        <NavbarTitle title="유저"/>,
        leading : [
            <SettingNavBtn/>,
            <EtcNavBtn object={<UserEtc/>} key={"EtcBtn"}/>,
        ]
    }
    return(<MobileNavbar route={route}/>);
}

const FeedListRoute = () =>{
    const [searchParams,] = useSearchParams();
    const route = (searchParams.get('page'))?{
        trailing : 
        <BackNavBtn/>,
    }:{
        trailing : 
        <BackNavBtn/>,
        leading : [
            <DrawerNavBtn key={"DrawerBtn"}/>,
        ]
    }
    return(<MobileNavbar route={route}/>);
}

const ListMenuView = ({children , obj} : {children : ReactNode , obj : ReactNode}) =>{
    const SetSmallMenu = useSetAtom(MenuObjectAtom);
    const [MenuTarget,setMenuTarget] = useAtom(MenuTargetAtom);
    const [on,setOn] = useState(false);
    const targetRef = useRef<any>();

    useEffect(()=>{
        if (MenuTarget?.target) setOn(true);
        else setOn(false);

        return ()=>setOn(false);
    },[MenuTarget])

    return (
        <div className={cx('flex-row')} onClick={(e)=>{SetSmallMenu(obj); setMenuTarget(e);}}>
            {children}
            <div ref={targetRef}
            style={{transform : (on)?'rotate(270deg)':'rotate(90deg)',
                pointerEvents : 'none',
                transition : 'transform 0.3s'
            }}>
                <RightBtn width = '12' height = '12'/>
            </div>
        </div>
    );
}

const FeedNavMenus = () =>{
    const pageSetting = useAtomValue(pageSettingAtom);
    return (
        <div className={cx('smallMenuSub')}>
            <CustomLink to={pageSetting.category} className={cx('smallMenuSubMenu','flex-row')}>
                카테고리
            </CustomLink>
            <CustomLink to={pageSetting.myTag} className={cx('smallMenuSubMenu','flex-row')}>
                태그
            </CustomLink>
            <CustomLink to={pageSetting.map} className={cx('smallMenuSubMenu','flex-row')}>
                내 위치
            </CustomLink>
        </div>
    );
}