import { CustomBtn } from "contents/func/anim/custom";
import { useEffect, useState } from "react";
import videoStyles from "./css/video.module.css";
import cn from "classnames/bind"
import { RightBtn, SettingBtn } from "icons";

const cx = cn.bind(videoStyles)

export const VideoControl = ({element}:{element : HTMLVideoElement}) =>{
    const [currentTime,setCurrentTime] = useState<number>();
    const [playing,setPlaying] = useState(!element?.paused);

    const timeUpdate = ()=>{
        element?.addEventListener("timeupdate",()=>{
            setCurrentTime(element.currentTime);
        })
    }

    const parseTime = (int? : number) =>{
        const date = new Date(int||0);
        const string = ([(date.getHours() > 1)&&date.getHours(),date.getMinutes(),date.getSeconds()]).join(":");

        console.log(string);

        return string;
    }

    const PlayButton = () =>{
        if (playing) {
            setPlaying(false)
            element.pause();
        }
        else {
            element.play().then((value)=>{
                console.log(value);
                setPlaying(true);
            },(err)=>console.log(err));
        }
    }

    useEffect(()=>{
        timeUpdate();

        return()=>{
            setCurrentTime(0);
        }
    },[]);
    
    return(
        <div className={cx("bottomController",'flex-row')}>
            <CustomBtn className={cx("controlBtn")} onClick={PlayButton}>
                <RightBtn width="20" height="20"></RightBtn>
            </CustomBtn>
            <div>
                <p>{parseTime(currentTime)}</p>
            </div>
            <CustomBtn className={cx("controlBtn")}>
                <SettingBtn width = "24" height = "24"></SettingBtn>
            </CustomBtn>
        </div>
    );
}